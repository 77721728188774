export default {
    BASKET_CONDITION: {
        IS_WORKING: {
            SMARTPHONE_APPLE: {
                TITLE: 'Est ce que votre <strong>iPhone</strong> fonctionne normalement ?',
                DESCRIPTION: 'Il s\'allume et s\'éteint normalement.<br/>Les boutons (on/off, volume, home…) fonctionnent normalement.<br/>L\'écran tactile, l\'appareil photo, le réseau etc… fonctionnent normalement.<br/>La batterie de votre smartphone charge correctement (une batterie inférieure à |battery| de sa capacité neuve ou un nombre de cycle de charge supérieur à 500 peut donner lieu à une décote).'
            },
            SMARTPHONE_OTHER: {
                TITLE: 'Est ce que votre <strong>smartphone</strong> fonctionne normalement ?',
                DESCRIPTION: 'Il s\'allume et s\'éteint normalement.<br/>Les boutons (on/off, volume, home…) fonctionnent normalement<br/>L\'écran tactile, l\'appareil photo, le réseau etc… fonctionnent normalement.<br/>La batterie de votre smartphone charge correctement (une batterie inférieure à |battery| de sa capacité neuve ou un nombre de cycle de charge supérieur à 500 peut donner lieu à une décote).'
            },
            LAPTOP: {
                TITLE: 'Est ce que votre <strong>ordinateur portable</strong> fonctionne normalement ?',
                DESCRIPTION: 'Il s\'allume et s\'éteint normalement.<br/>L\'ordinateur n\'affiche pas de message d\'alerte concernant la batterie.<br/>Toutes les fonctions de l\'ordinateur fonctionnent normalement.<br/>La batterie de votre ordinateur portable charge correctement (une batterie inférieure à |battery| de sa capacité neuve ou un nombre de cycle de charge supérieur à 500 peut donner lieu à une décote).'
            },
            OTHER: {
                TITLE: 'Est ce que votre produit fonctionne normalement ?',
                DESCRIPTION: 'Le produit ne génère pas de bug à l\'utilisation.<br/>Sa connectique est en parfait état.<br/>L\'absence de certains accessoires d\'origine peut donner lieu à une décôte.'
            },
            TABLET_APPLE: {
                TITLE: 'Est ce que votre <strong>iPad</strong> fonctionne normalement ?',
                DESCRIPTION: 'Il s\'allume et s\'éteint normalement.<br/>Les boutons (on/off, volume, home…) fonctionnent normalement.<br/>L\'écran tactile, l\'appareil photo, le wifi etc… fonctionnent normalement.<br/>La batterie de votre iPad charge correctement (une batterie inférieure à |battery| de sa capacité neuve ou un nombre de cycle de charge supérieur à 500 peut donner lieu à une décote).'
            },
            TABLET_OTHER: {
                TITLE: 'Est ce que votre <strong>tablette</strong> fonctionne normalement ?',
                DESCRIPTION: 'Elle s\'allume et s\'éteint normalement.<br/>Les boutons (on/off, volume, home…) fonctionnent normalement.<br/>L\'écran tactile, l\'appareil photo, le wifi etc… fonctionnent normalement.<br/>La batterie de votre tablette charge correctement (une batterie inférieure à |battery| de sa capacité neuve ou un nombre de cycle de charge supérieur à 500 peut donner lieu à une décote).'
            },
            CONSOLE: {
                TITLE: 'Est ce que votre <strong>console</strong> fonctionne normalement ?',
                DESCRIPTION: 'Elle s\'allume et s\'éteint normalement.<br/>Les boutons, la connectique et l\'écran tactile fonctionnent normalement.<br/>Pas de message d\'alerte concernant la batterie.<br/>La console lit correctement les jeux, sans surchauffe.'
            },
            GRAPHIC_CARD: {
                TITLE: 'Est ce que votre carte graphique fonctionne normalement ?',
                DESCRIPTION: 'Votre carte graphique est bien reconnue par votre ordinateur, elle ne génère pas de bugs à l\'utilisation.<br>Sa connectique est en parfait état.'
            },
            MONITOR: {
                TITLE: 'Est-ce que votre écran d\'ordinateur fonctionne normalement ?',
                DESCRIPTION: 'L\'écran d\'ordinateur s\'allume et s\'éteint normalement.<br/>Les boutons et la connectique fonctionnent Correctement.'
            },
            WATCH_APPLE: {
                TITLE: 'Est ce que votre Watch fonctionne normalement ?',
                DESCRIPTION: 'Elle s\'allume et s\'éteint normalement.<br/>Les boutons (on/off, volume, home…) et l\'écran tactile fonctionnent normalement.<br/>Pas de message d\'alerte concernant la batterie.'
            },
            WATCH_OTHER: {
                TITLE: 'Est ce que votre Watch fonctionne normalement ?',
                DESCRIPTION: 'Elle s\'allume et s\'éteint normalement.<br/>Les boutons (on/off, volume, home…) et l\'écran tactile fonctionnent normalement.<br/>Pas de message d\'alerte concernant la batterie.'
            },
            DESKTOP: {
                TITLE: 'Est ce que votre ordinateur fonctionne normalement ?',
                DESCRIPTION: 'Il s\'allume et et s\'éteint normalement.<br/>L\'ordinateur n\'affiche pas de message d\'alerte concernant la batterie.<br/>Toutes les fonctions de l\'ordinateur fonctionnent normalement.'
            },
            CAMERA:{
                TITLE: 'Est ce que votre appareil photo fonctionne normalement ?',
                DESCRIPTION: 'Il s\'allume et et s\'éteint normalement.<br/>L\'appareil photo n\'affiche pas de message d\'alerte concernant la batterie.<br/>Toutes les fonctions de l\'appareil photo fonctionnent normalement.'
            },
            DRILL_N_SCREWDRIVER: {
                TITLE: 'Est ce que votre perceuse et/ou visseuse fonctionne normalement ?',
                DESCRIPTION: 'Elle s\'allume et et s\'éteint normalement.<br/>Toutes les fonctions de la perceuse et/ou visseuse fonctionnent normalement.'
            },
            GARDEN: {
                TITLE: 'Est ce que votre produit fonctionne normalement ?',
                DESCRIPTION: 'Il s\'allume et et s\'éteint normalement.<br/>Toutes les fonctions du produit fonctionnent normalement.'
            },
            TOOLS: {
                TITLE: 'Est ce que votre outil fonctionne normalement ?',
                DESCRIPTION: 'Toutes les fonctions du produit fonctionnent normalement.'
            },
            HIGH_PRESSURE_WASHER: {
                TITLE: 'Est ce que votre nettoyeur fonctionne normalement ?',
                DESCRIPTION: 'Elle s\'allume et et s\'éteint normalement.<br/>Toutes les fonctions du nettoyeur fonctionnent normalement.'
            }
        },
        CONDITION: {
            SMARTPHONE_APPLE: {
                TITLE: 'Décrivez <strong>l\'aspect</strong> de votre iPhone',
                DESCRIPTION: 'L\'état de votre produit est vérifié à son arrivée.<br/>Si les informations fournies sont inexactes, votre produit pourra être refusé ou soumis à une contre-offre de reprise.<br/>En cas de retour, <a href="getCguLink(window.location.hostname.split(\'.\')[0])" target="_blank">les frais de livraison vous seront facturés</a>.'
            },
            SMARTPHONE_OTHER: {
                TITLE: 'Décrivez <strong>l\'aspect</strong> de votre smartphone',
                DESCRIPTION: 'L\'état de votre produit est vérifié à son arrivée.<br/>Si les informations fournies sont inexactes, votre produit pourra être refusé ou soumis à une contre-offre de reprise.<br/>En cas de retour, <a href="getCguLink(window.location.hostname.split(\'.\')[0])" target="_blank">les frais de livraison vous seront facturés</a>.'
            },
            LAPTOP: {
                TITLE: 'Décrivez <strong>l\'aspect</strong> de votre ordinateur portable',
                DESCRIPTION: 'L\'état de votre produit est vérifié à son arrivée.<br/>Si les informations fournies sont inexactes, votre produit pourra être refusé ou soumis à une contre-offre de reprise.<br/>En cas de retour, <a href="getCguLink(window.location.hostname.split(\'.\')[0])" target="_blank">les frais de livraison vous seront facturés</a>.'
            },
            OTHER: {
                TITLE: 'Décrivez <strong>l\'aspect</strong> de votre produit',
                DESCRIPTION: 'L\'état de votre produit est vérifié à son arrivée.<br/>Si les informations fournies sont inexactes, votre produit pourra être refusé ou soumis à une contre-offre de reprise.<br/>En cas de retour, <a href="getCguLink(window.location.hostname.split(\'.\')[0])" target="_blank">les frais de livraison vous seront facturés</a>.'
            },
            TABLET_APPLE: {
                TITLE: 'Décrivez <strong>l\'aspect</strong> de votre iPad',
                DESCRIPTION: 'L\'état de votre produit est vérifié à son arrivée.<br/>Si les informations fournies sont inexactes, votre produit pourra être refusé ou soumis à une contre-offre de reprise.<br/>En cas de retour, <a href="getCguLink(window.location.hostname.split(\'.\')[0])" target="_blank">les frais de livraison vous seront facturés</a>.'
            },
            TABLET_OTHER: {
                TITLE: 'Décrivez <strong>l\'aspect</strong> de votre tablette',
                DESCRIPTION: 'L\'état de votre produit est vérifié à son arrivée.<br/>Si les informations fournies sont inexactes, votre produit pourra être refusé ou soumis à une contre-offre de reprise.<br/>En cas de retour, <a href="getCguLink(window.location.hostname.split(\'.\')[0])" target="_blank">les frais de livraison vous seront facturés</a>.'
            },
            CONSOLE: {
                TITLE: 'Décrivez <strong>l\'aspect</strong> de votre console',
                DESCRIPTION: 'L\'état de votre produit est vérifié à son arrivée.<br/>Si les informations fournies sont inexactes, votre produit pourra être refusé ou soumis à une contre-offre de reprise.<br/>En cas de retour, <a href="getCguLink(window.location.hostname.split(\'.\')[0])" target="_blank">les frais de livraison vous seront facturés</a>.'
            },
            MONITOR: {
                TITLE: 'Décrivez <strong>l\'aspect</strong> de votre écran d\'ordinateur',
                DESCRIPTION: 'L\'état de votre produit est vérifié à son arrivée.<br/>Si les informations fournies sont inexactes, votre produit pourra être refusé ou soumis à une contre-offre de reprise.<br/>En cas de retour, <a href="getCguLink(window.location.hostname.split(\'.\')[0])" target="_blank">les frais de livraison vous seront facturés</a>.'
            },
            WATCH_APPLE: {
                TITLE: 'Décrivez l\'aspect de votre Watch',
                DESCRIPTION: 'L\'état de votre produit est vérifié à son arrivée.<br/>Si les informations fournies sont inexactes, votre produit pourra être refusé ou soumis à une contre-offre de reprise.<br/>En cas de retour, <a href="getCguLink(window.location.hostname.split(\'.\')[0])" target="_blank">les frais de livraison vous seront facturés</a>.'
            },
            WATCH_OTHER: {
                TITLE: 'Décrivez l\'aspect de votre montre',
                DESCRIPTION: 'L\'état de votre produit est vérifié à son arrivée.<br/>Si les informations fournies sont inexactes, votre produit pourra être refusé ou soumis à une contre-offre de reprise.<br/>En cas de retour, <a href="getCguLink(window.location.hostname.split(\'.\')[0])" target="_blank">les frais de livraison vous seront facturés</a>.'
            },
            DESKTOP: {
                TITLE: 'Décrivez l\'aspect de votre ordinateur',
                DESCRIPTION: 'L\'état de votre produit est vérifié à son arrivée.<br/>Si les informations fournies sont inexactes, votre produit pourra être refusé ou soumis à une contre-offre de reprise.<br/>En cas de retour, <a href="getCguLink(window.location.hostname.split(\'.\')[0])" target="_blank">les frais de livraison vous seront facturés</a>.'
            },
            CAMERA: {
                TITLE: 'Décrivez l\'aspect de votre appreil photo',
                DESCRIPTION: 'L\'état de votre produit est vérifié à son arrivée.<br/>Si les informations fournies sont inexactes, votre produit pourra être refusé ou soumis à une contre-offre de reprise.<br/>En cas de retour, <a href="getCguLink(window.location.hostname.split(\'.\')[0])" target="_blank">les frais de livraison vous seront facturés</a>.'
            },
            DRILL_N_SCREWDRIVER: {
                TITLE: 'Décrivez l\'aspect de votre perceuse et/ou visseuse',
                DESCRIPTION: 'L\'état de votre produit est vérifié à son arrivée.<br/>Si les informations fournies sont inexactes, votre produit pourra être refusé ou soumis à une contre-offre de reprise.<br/>En cas de retour, <a href="getCguLink(window.location.hostname.split(\'.\')[0])" target="_blank">les frais de livraison vous seront facturés</a>.'
            },
            GARDEN: {
                TITLE: 'Décrivez l\'aspect de votre produit',
                DESCRIPTION: 'L\'état de votre produit est vérifié à son arrivée.<br/>Si les informations fournies sont inexactes, votre produit pourra être refusé ou soumis à une contre-offre de reprise.<br/>En cas de retour, <a href="getCguLink(window.location.hostname.split(\'.\')[0])" target="_blank">les frais de livraison vous seront facturés</a>.'
            },
            TOOLS: {
                TITLE: 'Décrivez l\'aspect de votre outil',
                DESCRIPTION: 'L\'état de votre produit est vérifié à son arrivée.<br/>Si les informations fournies sont inexactes, votre produit pourra être refusé ou soumis à une contre-offre de reprise.<br/>En cas de retour, <a href="getCguLink(window.location.hostname.split(\'.\')[0])" target="_blank">les frais de livraison vous seront facturés</a>.'
            },
            HIGH_PRESSURE_WASHER: {
                TITLE: 'Décrivez l\'aspect de votre nettoyeur',
                DESCRIPTION: 'L\'état de votre produit est vérifié à son arrivée.<br/>Si les informations fournies sont inexactes, votre produit pourra être refusé ou soumis à une contre-offre de reprise.<br/>En cas de retour, <a href="getCguLink(window.location.hostname.split(\'.\')[0])" target="_blank">les frais de livraison vous seront facturés</a>.'
            }
        },
        IS_PASSWORD_PROTECTED: {
            LAPTOP: {
                TITLE: 'Votre ordinateur portable est-il bloqué par un mot de passe ?',
                DESCRIPTION: 'Il est important de supprimer tout mot de passe empêchant l\'utilisation de votre ordinateur portable.<br/>Voici la procédure pour <a target="_blank" href="https://support.microsoft.com/fr-fr/windows/puis-je-me-connecter-%C3%A0-windows-sans-mot-de-passe-a72b85ae-75ae-4cfa-5c7d-186a61fcce92">un ordinateur sous Windows</a> et la procédure pour <a href="https://support.apple.com/fr-fr/HT201065" target="_blank">un Macbook</a>.',
                ALERT: 'Merci de bien supprimer tout mot de passe empêchant l\'utilisation de votre ordinateur portable.<br/>Nous ne pourrons pas vous proposer d\'offre de reprise si ce n\'est pas le cas.<br/>Voici la procédure pour <a target="_blank" href="https://support.microsoft.com/fr-fr/windows/puis-je-me-connecter-%C3%A0-windows-sans-mot-de-passe-a72b85ae-75ae-4cfa-5c7d-186a61fcce92">un ordinateur sous Windows</a> et la procédure pour <a href="https://support.apple.com/fr-fr/HT201065" target="_blank">un Macbook</a>.'
            },
            DESKTOP: {
                TITLE: 'Votre ordinateur est-il bloqué par un mot de passe ?',
                DESCRIPTION: 'Il est important de supprimer tout mot de passe empêchant l\'utilisation de votre ordinateur.<br/>Voici la procédure pour <a target="_blank" href="https://support.microsoft.com/fr-fr/windows/puis-je-me-connecter-%C3%A0-windows-sans-mot-de-passe-a72b85ae-75ae-4cfa-5c7d-186a61fcce92">un ordinateur sous Windows</a> et la procédure pour <a href="https://support.apple.com/fr-fr/HT201065" target="_blank">un Macbook</a>.',
                ALERT: 'Merci de bien supprimer tout mot de passe empêchant l\'utilisation de votre ordinateur.<br/>Nous ne pourrons pas vous proposer d\'offre de reprise si ce n\'est pas le cas.<br/>Voici la procédure pour <a target="_blank" href="https://support.microsoft.com/fr-fr/windows/puis-je-me-connecter-%C3%A0-windows-sans-mot-de-passe-a72b85ae-75ae-4cfa-5c7d-186a61fcce92">un ordinateur sous Windows</a> et la procédure pour <a href="https://support.apple.com/fr-fr/HT201065" target="_blank">un Macbook</a>.'
            }
        },
        IS_RESETED: {
            SMARTPHONE_APPLE: {
                TITLE: 'Votre iPhone est-il <strong>réinitialisé</strong> ?',
                DESCRIPTION: 'Il est important de réinitialiser votre iPhone afin de ne pas laisser de données personnelles dessus.<br/>Pensez à bien transférer vos données avant de le réinitialiser.<br/>Voici <a target="_blank" href="https://support.apple.com/fr-fr/HT201351">la procédure</a> pour transférer vos données et réinitialiser votre iPhone.',
                ALERT: 'Merci de bien réinitialiser votre iPhone avant de l’expédier. Nous ne pourrons pas vous proposer d\'offre de reprise si ce n\'est pas le cas.<br />Voici <a target="_blank" href="https://support.apple.com/fr-fr/HT201351">la procédure</a> pour transférer vos données et réinitialiser votre iPhone."'
            },
            SMARTPHONE_OTHER: {
                TITLE: 'Votre smartphone est-il <strong>réinitialisé</strong> ?',
                DESCRIPTION: 'Il est important de réinitialiser votre smartphone afin de ne pas laisser de données personnelles dessus.<br/>Pensez à bien transférer vos données avant de le réinitialiser.<br/>Voici les procédures pour <a target="_blank" href="https://support.google.com/android/answer/2819582?hl=fr">transférer vos données</a> et <a target="_blank" href="https://support.google.com/android/answer/6088915?hl=fr">réinitialiser votre smartphone</a>.',
                ALERT: 'Merci de bien réinitialiser votre smartphone. Nous ne pourrons pas vous proposer d\'offre de reprise si ce n\'est pas le cas.<br/>Voici les procédures pour <a target="_blank" href="https://support.google.com/android/answer/2819582?hl=fr">transférer vos données</a> et <a target="_blank" href="https://support.google.com/android/answer/6088915?hl=fr">réinitialiser votre smartphone</a>.'
            },
            TABLET_APPLE: {
                TITLE: 'Votre iPad est-il <strong>réinitialisé</strong> ?',
                DESCRIPTION: 'Il est important de réinitialiser votre iPad afin de ne pas laisser de données personnelles dessus.<br/>Pensez à bien transférer vos données avant de le réinitialiser.<br/>Voici <a target="_blank" href="https://support.apple.com/fr-fr/HT201351">la procédure</a> pour transférer vos données et réinitialiser votre iPad.',
                ALERT: 'Merci de bien réinitialiser votre iPad. Nous ne pourrons pas vous proposer d\'offre de reprise si ce n\'est pas le cas.<br />Voici <a target="_blank" href="https://support.apple.com/fr-fr/HT201351">la procédure</a> pour transférer vos données et réinitialiser votre iPad."'
            },
            TABLET_OTHER: {
                TITLE: 'Votre tablette est-elle <strong>réinitialisée</strong> ?',
                DESCRIPTION: 'Il est important de réinitialiser votre tablette afin de ne pas laisser de données personnelles dessus.<br/>Pensez à bien transférer vos données avant de la réinitialiser.<br/>Voici les procédures pour <a target="_blank" href="https://support.google.com/android/answer/2819582?hl=fr">transférer vos données</a> et <a target="_blank" href="https://support.google.com/android/answer/6088915?hl=fr">réinitialiser votre tablette</a>.',
                ALERT: 'Merci de bien réinitialiser votre tablette. Nous ne pourrons pas vous proposer d\'offre de reprise si ce n\'est pas le cas.<br/>Voici les procédures pour <a target="_blank" href="https://support.google.com/android/answer/2819582?hl=fr">transférer vos données</a> et <a target="_blank" href="https://support.google.com/android/answer/6088915?hl=fr">réinitialiser votre tablette</a>.'
            },
            WATCH_APPLE: {
                TITLE: 'Votre Watch est-elle <strong>jumelée</strong> avec un iPhone ?',
                DESCRIPTION: 'Il est important de désactiver le jumelage de votre Watch afin de ne pas laisser de données personnelles dessus.<br/>Voici <a target="_blank" href="https://support.apple.com/fr-fr/HT204568">la procédure pour déjumeler votre Watch</a>.',
                ALERT: 'Merci de bien désactiver le jumelage de votre Watch Nous ne pourrons pas vous proposer d\'offre de reprise si ce n\'est pas le cas. Voici <a target="_blank" href="https://support.apple.com/fr-fr/HT204568">la procédure</a>.'
            },
            WATCH_OTHER: {
                TITLE: 'Votre montre est-elle <strong>réinitialisée</strong> ?',
                DESCRIPTION: 'Il est important de réinitialiser votre montre afin de ne pas laisser de données personnelles dessus.<br/>Voici <a target="_blank" href="https://support.google.com/wearos/answer/6056905?hl=fr">les procédures pour réinitialiser votre montre</a>.',
                ALERT: 'Merci de bien réinitialiser votre montre. Nous ne pourrons pas vous proposer d\'offre de reprise si ce n\'est pas le cas. Voici <a target="_blank" href="https://support.google.com/wearos/answer/6056905?hl=fr">les procédures pour réinitialiser votre montre</a>.'
            },
        },
        IS_GEOLOC_ACTIVATED: {
            SMARTPHONE_APPLE: {
                TITLE: 'Est ce que votre iPhone est bloqué opérateur ou par la fonction Localiser mon iPhone ?',
                DESCRIPTION: 'Attention, aucun produit avec la fonction "Localiser mon iPhone" activée ou bloqué opérateur ne peut être repris.<br/>Voici <a target="_blank" href="https://support.apple.com/fr-fr/HT211149">la procédure</a> pour désactiver la fonction "Localiser mon iPhone".',
                ALERT: 'Attention, aucun produit avec la fonction "Localiser mon iPhone" activée ou bloqué opérateur ne peut être repris.<br/>Voici <a target="_blank" href="https://support.apple.com/fr-fr/HT211149">la procédure</a> pour désactiver la fonction "Localiser mon iPhone".'
            },
            SMARTPHONE_OTHER: {
                TITLE: 'Est ce que votre smartphone est bloqué opérateur ou par un compte GOOGLE ?',
                DESCRIPTION: 'Attention, aucun produit avec un compte GOOGLE ou un opérateur associé ne peut être repris.<br/>Voici <a href="https://support.google.com/accounts/answer/81987" target="_blank">la procédure</a> pour désactiver votre compte GOOGLE sur votre appareil.',
                ALERT: 'Attention, aucun produit avec un compte GOOGLE ou un opérateur associé ne peut être repris.<br/>Voici <a href="https://support.google.com/accounts/answer/81987" target="_blank">la procédure</a> pour désactiver votre compte GOOGLE sur votre appareil.'
            },
            TABLET_OTHER: {
                TITLE: 'Est ce qu\'un compte GOOGLE est associé à votre produit ?',
                DESCRIPTION: 'Attention, aucun produit avec un compte GOOGLE associé ne peut être repris.<br/>Voici <a href="https://support.google.com/accounts/answer/81987" target="_blank">la procédure</a> pour désactiver votre compte sur votre appareil.',
                ALERT: 'Attention, aucun produit avec un compte GOOGLE associé ne peut être repris.<br/>Voici <a href="https://support.google.com/accounts/answer/81987" target="_blank">la procédure</a> pour désactiver votre compte sur votre appareil.'
            }
        },
        IS_NO_STANDARD_USE: {
            OTHER: {
                TITLE: 'Est ce que le produit a été utilisé pour un usage inhabituel ?',
                DESCRIPTION: '"Overcloking, surchauffe...<br/>L\'état de votre produit est vérifié à son arrivée. Si les informations fournies sont inexactes, votre produit pourra être refusé ou soumis à une contre-offre de reprise.<br/>En cas de retour, <a href="getCguLink(window.location.hostname.split(\'.\')[0])" target="_blank">les frais de livraison vous seront facturés</a>.',
                ALERT: 'Désolé nous ne reprenons pas de produit utilisé de façon anormale.'
            },
            GRAPHIC_CARD: {
                TITLE: 'Est ce que la carte graphique a été utilisée pour un usage inhabituel ?',
                DESCRIPTION: 'overclocking, minage ...<br/>Chaque produit est vérifié à son arrivée. Si les informations fournies sont inexactes, votre produit pourra être refusé ou soumis à une contre-offre de reprise.<br>En cas de retour, <a href="getCguLink(window.location.hostname.split(\'.\')[0])" target="_blank">les frais de livraison vous seront facturés</a>.',
                ALERT: 'Désolé nous ne reprenons pas de produit utilisé de façon anormale.'
            },
            CAMERA: {
                TITLE: 'Est-ce que votre appareil a un nombre de déclenchements supérieur à <strong>100 000</strong> ?',
                DESCRIPTION: 'Référez vous au manuel d’utilisation de votre appareil afin de vérifier dans son interface le nombre de déclenchements / prises de photos indiqué.',
                ALERT: 'Désolé, aucun appareil avec un nombre de déclenchements supérieur à 100 000 ne peut être repris.'
            }
        },
        IS_COMPLETE: {
            CONSOLE: {
                TITLE: 'La console est-elle complète ?',
                DESCRIPTION: "Le câble d'alimentation, le câble HDMI, le chargeur et les manettes vendues avec la console sont présents et fonctionnels."
            },
            LAPTOP: {
                TITLE: 'Disposez vous du <strong>chargeur d\'origine</strong> de votre ordinateur portable ?',
                DESCRIPTION: ''
            },
            DESKTOP: {
                TITLE: 'Disposez vous du <strong>chargeur d\'origine</strong> de votre ordinateur ?',
                DESCRIPTION: ''
            },
            CAMERA: {
                TITLE: 'Disposez vous du <strong>chargeur d\'origine</strong> et de tous les <strong>accessoires</strong> ?',
                DESCRIPTION: ''
            },
            DRILL_N_SCREWDRIVER: {
                TITLE: 'Disposez vous du <strong>chargeur d\'origine</strong> et de tous les <strong>accessoires</strong> ?',
                DESCRIPTION: ''
            },
            GARDEN: {
                TITLE: 'Disposez vous du <strong>chargeur d\'origine</strong> (si il y en a un) et de tous les <strong>accessoires</strong> ?',
                DESCRIPTION: ''
            },
            TOOLS: {
                TITLE: 'Disposez vous du <strong>chargeur d\'origine</strong> (si il y en a un) et de tous les <strong>accessoires</strong> ?',
                DESCRIPTION: ''
            },
            HIGH_PRESSURE_WASHER: {
                TITLE: 'Disposez vous du <strong>chargeur d\'origine</strong> et de tous les <strong>accessoires</strong> ?',
                DESCRIPTION: ''
            }
        }
    },
    BASKET_CONDITION_LABEL: {
        SMARTPHONE_APPLE: {
            1: "Comme neuf - <br class='d-md-none'>aucune trace d'usure",
            2: "Très bon état - <br class='d-md-none'>légères traces d'usure, micro rayures",
            3: "Bon état - <br class='d-md-none'>légères rayures, pas de choc <br class='d-md-none'>ou de rayures profondes",
            4: "Correct - <br class='d-md-none'>fortes marques d'utilisation, <br class='d-md-none'>chocs, taches sur l'écran ou la coque",
            5: "Endommagé - <br class='d-md-none'>écran fissuré, erreurs de couleur, <br class='d-md-none'>coque fissurée, iPhone déformé"
        },
        SMARTPHONE_OTHER: {
            1: "Comme neuf - <br class='d-md-none'>aucune trace d'usure",
            2: "Très bon état - <br class='d-md-none'>légères traces d'usure, micro rayures",
            3: "Bon état - <br class='d-md-none'>légères rayures, pas de choc <br class='d-md-none'>ou de rayures profondes",
            4: "Correct - <br class='d-md-none'>fortes marques d'utilisation, <br class='d-md-none'>chocs, taches sur l'écran ou la coque",
            5: "Endommagé - <br class='d-md-none'>écran fissuré, erreurs de couleur, <br class='d-md-none'>coque fissurée, smartphone déformé"
        },
        LAPTOP: {
            1: "Comme neuf - <br class='d-md-none'>aucune trace d'usure",
            2: "Très bon état - <br class='d-md-none'>légères traces d'usure, <br class='d-md-none'>micro rayures",
            3: "Bon état - <br class='d-md-none'>légères rayures, pas de choc <br class='d-md-none'>ou de rayures profondes",
            4: "Correct - <br class='d-md-none'>fortes marques d'utilisation, chocs, <br class='d-md-none'>taches sur l'écran ou la châssis",
            5: "Endommagé - <br class='d-md-none'>écran fissuré, erreurs de couleur, <br class='d-md-none'>châssis fissuré, ordinateur déformé"
        },
        DESKTOP: {
            1: "Comme neuf - <br class='d-md-none'>aucune trace d'usure sur le châssis ou l'écran",
            2: "Très bon état - <br class='d-md-none'>légères traces d'usure, <br class='d-md-none'>micro rayures sur le châssis ou l'écran",
            3: "Bon état - <br class='d-md-none'>légères rayures, pas de choc <br class='d-md-none'>ou de rayures profondes sur le châssis ou l'écran",
            4: "Correct - <br class='d-md-none'>fortes marques d'utilisation, chocs, <br class='d-md-none'>taches sur l'écran ou la châssis",
            5: "Endommagé - <br class='d-md-none'>écran fissuré, erreurs de couleur, <br class='d-md-none'>châssis fissuré, ordinateur déformé"
        },
        OTHER: {
            1: "Comme neuf - <br class='d-md-none'>aucune trace d'usure",
            2: "Très bon état - <br class='d-md-none'>légères traces d'usure, <br class='d-md-none'>micro rayures",
            3: "Bon état - <br class='d-md-none'>légères rayures, pas de choc <br class='d-md-none'>ou de rayures profondes",
            4: "Correct - <br class='d-md-none'>fortes marques d'utilisation, <br class='d-md-none'>chocs",
            5: "Endommagé - <br class='d-md-none'>fissuré, déformé"
        },
        CONSOLE: {
            1: "Comme neuf - <br class='d-md-none'>aucune trace d'usure",
            2: "Très bon état - <br class='d-md-none'>légères traces d'usure, <br class='d-md-none'>micro rayures",
            3: "Bon état - <br class='d-md-none'>légères rayures, pas de choc <br class='d-md-none'>ou de rayures profondes",
            4: "Correct - <br class='d-md-none'>fortes marques d'utilisation, <br class='d-md-none'>chocs",
            5: "Endommagé - <br class='d-md-none'>fissuré, déformé"
        },
        TABLET_APPLE: {
            1: "Comme neuf - <br class='d-md-none'>aucune trace d'usure",
            2: "Très bon état - <br class='d-md-none'>légères traces d'usure, <br class='d-md-none'>micro rayures",
            3: "Bon état - <br class='d-md-none'>légères rayures, pas de choc <br class='d-md-none'>ou de rayures profondes",
            4: "Correct - <br class='d-md-none'>fortes marques d'utilisation, <br class='d-md-none'>chocs",
            5: "Endommagé - <br class='d-md-none'>fissuré, déformé"
        },
        TABLET_OTHER: {
            1: "Comme neuf - <br class='d-md-none'>aucune trace d'usure",
            2: "Très bon état - <br class='d-md-none'>légères traces d'usure, <br class='d-md-none'>micro rayures",
            3: "Bon état - <br class='d-md-none'>légères rayures, pas de choc <br class='d-md-none'>ou de rayures profondes",
            4: "Correct - <br class='d-md-none'>fortes marques d'utilisation, <br class='d-md-none'>chocs",
            5: "Endommagé - <br class='d-md-none'>fissuré, déformé"
        },
        MONITOR: {
            1: "Comme neuf - <br class='d-md-none'>aucune trace d'usure",
            2: "Très bon état - <br class='d-md-none'>écran sans pixels morts, <br class='d-md-none'>sans tâches sur l'écran, <br>pas de chocs ou de rayures sur les bords de l'écran",
            3: "Bon état - <br class='d-md-none'>2 pixels morts maximum, <br class='d-md-none'>sans tâches et rayures sur l'écran, <br class='d-md-none'>pas de chocs sur les bords de l'écran, <br>légères rayures sur les bords de l'écran",
            4: "Correct - <br class='d-md-none'>2 pixels morts maximum, <br class='d-md-none'>sans tâches et rayures sur l'écran, <br class='d-md-none'>pas de chocs sur les bords de l'écran, <br>nombreuses rayures sur les bords de l'écran",
            5: "Endommagé - <br class='d-md-none'>plus de 2 pixels morts, <br class='d-md-none'>tâches et rayures sur l'écran"
        },
        WATCH_APPLE: {
            1: "Comme neuf - <br class='d-md-none'>aucune trace d'usure",
            2: "Très bon état - <br class='d-md-none'>légères traces d'usure, <br class='d-md-none'>micro rayures",
            3: "Bon état - <br class='d-md-none'>légères rayures, <br class='d-md-none'>sans tâches et rayures sur l'écran, <br class='d-md-none'>pas de choc ou de rayures profondes",
            4: "Correct - <br class='d-md-none'>fortes marques d'utilisation, <br class='d-md-none'>chocs, taches sur l'écran ou le châssis",
            5: "Endommagé - <br class='d-md-none'>écran fissuré, <br class='d-md-none'>erreurs de couleur, <br class='d-md-none'>châssis fissuré, <br class='d-md-none'>montre déformée"
        },
        WATCH_OTHER: {
            1: "Comme neuf - <br class='d-md-none'>aucune trace d'usure",
            2: "Très bon état - <br class='d-md-none'>légères traces d'usure, <br class='d-md-none'>micro rayures",
            3: "Bon état - <br class='d-md-none'>légères rayures, <br class='d-md-none'>sans tâches et rayures sur l'écran, <br class='d-md-none'>pas de choc ou de rayures profondes",
            4: "Correct - <br class='d-md-none'>fortes marques d'utilisation, <br class='d-md-none'>chocs, taches sur l'écran ou le châssis",
            5: "Endommagé - <br class='d-md-none'>écran fissuré, <br class='d-md-none'>erreurs de couleur, <br class='d-md-none'>châssis fissuré, <br class='d-md-none'>montre déformée"
        },
        CAMERA: {
            1: "Comme neuf - <br class='d-md-none'>aucune trace d'usure",
            2: "Très bon état - <br class='d-md-none'>légères traces d'usure, <br class='d-md-none'>micro rayures",
            3: "Bon état - <br class='d-md-none'>légères rayures, <br class='d-md-none'>sans tâches et rayures sur l'écran, <br class='d-md-none'>pas de choc ou de rayures profondes",
            4: "Correct - <br class='d-md-none'>fortes marques d'utilisation, <br class='d-md-none'>chocs, taches sur l'écran ou le châssis",
            5: "Endommagé - <br class='d-md-none'>écran fissuré, <br class='d-md-none'>erreurs de couleur, <br class='d-md-none'>châssis fissuré, <br class='d-md-none'>montre déformée"
        },
        DRILL_N_SCREWDRIVER: {
            1: "Comme neuf - <br class='d-md-none'>aucune trace d'usure",
            2: "Très bon état - <br class='d-md-none'>légères traces d'usure, <br class='d-md-none'>micro rayures",
            3: "Bon état - <br class='d-md-none'>légères rayures, <br class='d-md-none'>sans tâches et rayures sur l'écran, <br class='d-md-none'>pas de choc ou de rayures profondes",
            4: "Correct - <br class='d-md-none'>fortes marques d'utilisation, <br class='d-md-none'>chocs, taches sur l'écran ou le châssis",
            5: "Endommagé - <br class='d-md-none'>écran fissuré, <br class='d-md-none'>erreurs de couleur, <br class='d-md-none'>châssis fissuré, <br class='d-md-none'>montre déformée"
        },
        GARDEN: {
            1: "Comme neuf - <br class='d-md-none'>aucune trace d'usure",
            2: "Très bon état - <br class='d-md-none'>légères traces d'usure, <br class='d-md-none'>micro rayures",
            3: "Bon état - <br class='d-md-none'>légères rayures, <br class='d-md-none'>sans tâches et rayures sur l'écran, <br class='d-md-none'>pas de choc ou de rayures profondes",
            4: "Correct - <br class='d-md-none'>fortes marques d'utilisation, <br class='d-md-none'>chocs, taches sur l'écran ou le châssis",
            5: "Endommagé - <br class='d-md-none'>écran fissuré, <br class='d-md-none'>erreurs de couleur, <br class='d-md-none'>châssis fissuré, <br class='d-md-none'>montre déformée"
        },
        TOOLS: {
            1: "Comme neuf - <br class='d-md-none'>aucune trace d'usure",
            2: "Très bon état - <br class='d-md-none'>légères traces d'usure, <br class='d-md-none'>micro rayures",
            3: "Bon état - <br class='d-md-none'>légères rayures, <br class='d-md-none'>sans tâches et rayures sur l'écran, <br class='d-md-none'>pas de choc ou de rayures profondes",
            4: "Correct - <br class='d-md-none'>fortes marques d'utilisation, <br class='d-md-none'>chocs, taches sur l'écran ou le châssis",
            5: "Endommagé - <br class='d-md-none'>écran fissuré, <br class='d-md-none'>erreurs de couleur, <br class='d-md-none'>châssis fissuré, <br class='d-md-none'>montre déformée"
        },
        HIGH_PRESSURE_WASHER: {
            1: "Comme neuf - <br class='d-md-none'>aucune trace d'usure",
            2: "Très bon état - <br class='d-md-none'>légères traces d'usure, <br class='d-md-none'>micro rayures",
            3: "Bon état - <br class='d-md-none'>légères rayures, <br class='d-md-none'>sans tâches et rayures sur l'écran, <br class='d-md-none'>pas de choc ou de rayures profondes",
            4: "Correct - <br class='d-md-none'>fortes marques d'utilisation, <br class='d-md-none'>chocs, taches sur l'écran ou le châssis",
            5: "Endommagé - <br class='d-md-none'>écran fissuré, <br class='d-md-none'>erreurs de couleur, <br class='d-md-none'>châssis fissuré, <br class='d-md-none'>montre déformée"
        },
    },
    STATUS: {
        STATUS_1: 'A confirmer',
        STATUS_2: 'A expédier',
        STATUS_3: 'Livraison en cours',
        STATUS_4: 'Contrôle du produit<br/>en cours',
        STATUS_5: 'Revente validée',
        STATUS_6: 'Contre offre<br/>à valider',
        STATUS_7: 'Contrôle du Produit<br/>en attente',
        STATUS_8: 'Choisir un type<br/>de bon d\'achat',
        STATUS_9: 'Revente annulée<br/>Produit à récupérer',
        STATUS_10: 'Terminé'
    },
    STATUS_INFO: {
        STATUS_1: "Merci de signer votre bon de cession pour valider l'offre de rachat de votre produit.",
        STATUS_2: "Merci d'expédier votre produit avec l'étiquette de transport fournie.",
        STATUS_3: 'Votre produit est en cours de livraison.',
        STATUS_4: 'Votre produit est en cours de contrôle.',
        STATUS_5: "Votre revente est validée, vous allez recevoir votre bon d'achat d'ici 3 jours.",
        STATUS_6: "Merci d'accepter ou de décliner la contre offre de rachat de votre produit.",
        STATUS_7: 'Vous allez être contacté afin de débloquer le contrôle de votre produit.',
        STATUS_8: "Merci de choisir un type de bon d'achat",
        STATUS_9: 'Votre produit va vous être renvoyé. Merci de régler les frais de livraison.',
        STATUS_10: 'Terminé'
    },
    CATEGORY: {
        LAPTOP: 'ordinateur portable',
        SMARTPHONE_APPLE: 'smartphone',
        SMARTPHONE_OTHER: 'smartphone',
        TABLET_APPLE: 'tablette',
        TABLET_OTHER: 'tablette',
        OTHER: 'produit',
        CONSOLE: 'console',
        GRAPHIC_CARD: 'carte graphique',
        MONITOR: 'écran d\'ordinateur',
        WATCH_APPLE: 'Apple watch',
        WATCH_OTHER: 'montre connectée',
        DESKTOP: 'ordinateur',
        CAMERA: 'appareil photo',
        DRILL_N_SCREWDRIVER: 'perceuse et/ou visseuse',
        GARDEN: 'jardin',
        TOOLS: 'Outillage',
        HIGH_PRESSURE_WASHER: 'nettoyeur haute-pression',
    },
    SEARCH_FORM: {
        QUESTIONS: {
            category: {
                question: 'Quel produit voulez vous revendre ?',
                label: 'Type de produit'
            },
            displayed_brand: {
                question: 'Quelle est sa marque ?',
                label: 'Marque'
            },
            family: {
                question: 'Quelle est sa famille ?',
                label: 'Famille'
            },
            sub_family: {
                question: 'Quelle est sa sous famille ?',
                label: 'Sous famille'
            },
            model: {
                question: 'Quel est son modèle ?',
                label: 'Modèle'
            },
            option: {
                question: 'Quelle option possède t\'il ?',
                label: 'Option'
            },
            capacity: {
                question: 'Quelle est sa capacité de stockage ?',
                label: 'Capacité'
            },
            processor_generation: {
                question: 'Quelle est sa génération de procésseur ?',
                label: 'Génération de processeur'
            },
            gpu: {
                question: 'Quelle est sa carte graphique ?',
                label: 'Carte graphique'
            },
            hard_drive: {
                question: 'Quel est son type de disque dur ?',
                label: 'Type de disque dur'
            },
            ram: {
                question: 'Quelle est sa capacité de la mémoire vive (RAM) ?',
                label: 'Mémoire vive'
            },
            diagonal: {
                question: 'Quelle est sa taille d\'écran ?',
                label: 'Taille de l\'écran'
            },
            processor_family: {
                question: 'Quelle est sa famille de processeur ?',
                label: 'Famille de procésseur'
            },
            display_type: {
                question: 'Quel est son type d\'écran ?',
                label: 'Type d\'écran'
            },
            monitor_diagonal: {
                question: 'Quelle est la taille de votre écran ?',
                label: 'Taille de l\'écran'
            },
            monitor_screen_type: {
                question: 'De quel type est votre écran ?',
                label: 'Type d\'écran'
            },
            monitor_max_resolution: {
                question: 'Quelle est la résolution de votre écran ?',
                label: 'Résolution d\'écran'
            },
            monitor_connectors: {
                question: 'Quelles types de connection à votre écran ?',
                label: 'Type de connections'
            },
            monitor_curve_type: {
                question: 'Votre écran est il incruvé ?',
                label: 'Ecran incurvé'
            },
            monitor_has_speakers: {
                question: 'Votre écran a t il des enceintes intégrées ?',
                label: 'Enceintes intégrées'
            }
        }
    },
    REINSURANCE_MESSAGES: {

        rueducommerce: {

            SEARCH_FORM: {
    
                message1 : {
    
                    question: 'Le service de revente est-il accessible à tout le monde ?',
                    answer: "Le service est accessible aux résidents en France. <br> La livraison de produits peut être possible pour d'autres pays. <br> Malheureusement, pour l'instant, le service n'est pas disponible pour les personnes ayant une adresse de livraison dans ces pays."
                },
                message2 : {
    
                    question: 'Est-ce que je peux revendre un appareil cassé ?',
                    answer: "Oui, les professionnels qui rachètent vos appareils sont tout à faits capables de les réparer, même si ils sont cassés. <br> Donc n'hésitez pas à regarder quel prix de reprise nous vous proposons, quel que soit l'état de votre appareil."
                },
                message3 : {
    
                    question: "GREENDID, c'est quoi ?",
                    answer: "GREENDID est la première plateforme de reventes de produits directement depuis votre suivi de commandes. <br><br> NOTRE MISSION : vous permettre de revendre vos produits le plus simplement possible et au meilleur prix. <br><br> - Pas d'annonce à créer <br> - Pas de prix à négocier <br> - Pas de frais cachés <br><br>COMMENT CA MARCHE ? <br><br> Choisissez votre produit à revendre dans votre suivi de commandes ou via le formulaire <br> Décrivez l'état de votre produit pour obtenir notre meilleure offre de reprise <br> Chosissez entre une carte cadeau immédiate ou différée <br> Expédiez votre produit gratuitement. <br> C'est simple et rapide !" 
                },
                message4 : {
    
                    question: "Comment puis-je vous contacter si j'ai une question ?",
                    answer: "Pour nous contacter c'est très simple, vous pouvez utiliser le chat disponible en bas à droite du site ou bien nous envoyer un mail à l'adresse {emailLink}"
                },
            },
            BASKET_CONDITIONS: {
    
                message1 : {
    
                    question: "Comment savoir quel état indiquer pour mon produit ?",
                    answer: "Afin de vous aider dans le choix de l'état de votre produit, vous pouvez cliquer sur l'icône \"?\" à côté de la question. <br> Vous obtiendrez des précisions sur chaque état."
                },
                message2 : {
    
                    question: "Est-ce que je peux revendre un appareil cassé ?",
                    answer: "Oui, les professionnels qui rachètent vos appareils sont tout à faits capables de les réparer, même si ils sont cassés, donc n'hésitez pas à regarder quel prix de reprise nous vous proposons, quel que soit l'état de votre appareil."
                },
                message3 : {
    
                    question: "GREENDID, c'est quoi ?",
                    answer: "GREENDID est la première plateforme de reventes de produits directement depuis votre suivi de commandes. <br><br> NOTRE MISSION : vous permettre de revendre vos produits le plus simplement possible et au meilleur prix. <br><br> - Pas d'annonce à créer <br> - Pas de prix à négocier <br> - Pas de frais cachés <br><br>COMMENT CA MARCHE ? <br><br> Choisissez votre produit à revendre dans votre suivi de commandes ou via le formulaire <br> Décrivez l'état de votre produit pour obtenir notre meilleure offre de reprise <br> Chosissez entre une carte cadeau immédiate ou différée <br> Expédiez votre produit gratuitement. <br> C'est simple et rapide !" 
                },
                message4 : {
    
                    question: "Comment puis-je vous contacter si j'ai une question ?",
                    answer: "Pour nous contacter c'est très simple, vous pouvez utiliser le chat disponible en bas à droite du site ou bien nous envoyer un mail à l'adresse {emailLink}"
                },
            },
            SIGN_IN: {
    
                message1 : {
    
                    question: "Pourquoi est-ce que je dois créer un compte GREENDID ?",
                    answer: "Nous vous demandons de créer un compte GREENDID afin d'éviter tout transfert d'informations personnelles. <br> A la création du compte GREENDID, nous vous demandons uniquement les informations nécessaires à la revente de votre produit. <br> Ainsi vous validez bien toutes les informations que vous nous fournissez."
                },
                message2 : {
    
                    question: "GREENDID, c'est quoi ?",
                    answer: "GREENDID est la première plateforme de reventes de produits directement depuis votre suivi de commandes. <br><br> NOTRE MISSION : vous permettre de revendre vos produits le plus simplement possible et au meilleur prix. <br><br> - Pas d'annonce à créer <br> - Pas de prix à négocier <br> - Pas de frais cachés <br><br>COMMENT CA MARCHE ? <br><br> Choisissez votre produit à revendre dans votre suivi de commandes ou via le formulaire <br> Décrivez l'état de votre produit pour obtenir notre meilleure offre de reprise <br> Chosissez entre une carte cadeau immédiate ou différée <br> Expédiez votre produit gratuitement. <br> C'est simple et rapide !" 
                },
                message3 : {
    
                    question: "Comment puis-je vous contacter si j'ai une question ?",
                    answer: "Pour nous contacter c'est très simple, vous pouvez utiliser le chat disponible en bas à droite du site ou bien nous envoyer un mail à l'adresse {emailLink}"
                },
            },
            COUPON_TYPE: {
    
                message1 : {
    
                    question: "Que puis-je acheter avec mon bon d'achat ?",
                    answer: "Une fois la reprise validée, vous recevrez un bon d'achat dans votre espace client Rue du Commerce. Ce bon d'achat sera valable 6 mois sur tous les produits vendus sur le site Rue du Commerce, y compris les produits vendus par un marchand de la marketplace."
                },
                message2 : {
    
                    question: "Quelle différence entre le bon d'achat immédiat et le bon d'achat différé ?",
                    answer: "Vous pouvez choisir de recevoir votre bon d'achat avant même d'expédier le produit revendu. <br> Pour cela, il faut choisir le bon d'achat immédiat. <br> On vous demande juste une empreinte de votre carte bancaire au cas où vous changeriez d'avis. <br> Cela vous permet d'acheter un nouveau produit avant d'expédier le produit revendu. <br> <br> Si vous n'êtes pas pressé, vous pouvez choisir le bon d'achat différé. <br> Vous le recevrez après validation de votre produit par notre partenaire."
                },
                message3 : {
    
                    question: "Comment fonctionnent les bonus / codes promo ?",
                    answer: "A certaines périodes, en revendant l'un de vos produits, vous aurez le droit de choisir un bonus sous la forme d'un code promo permettant d'appliquer une réduction lors de l'achat d'un nouveau produit. <br> <br> Ces codes promo ne sont valables que pendant une certaine période et uniquement sur une liste définie de produits. <br> <br> Les conditions d'obtention et d'application de ces codes promos sont expliquées lors de chaque opération. <br> <br> Ces codes promo sont cumulables avec l'utilisation d'un ou plusieurs bons d'achat mais ne sont pas cumulables avec l'utilisation d'autres codes promo. <br> Ils permettent d'obtenir une réduction sur l'achat d'un futur produit."
                },
                message4 : {
    
                    question: "GREENDID, c'est quoi ?",
                    answer: "GREENDID est la première plateforme de reventes de produits directement depuis votre suivi de commandes. <br><br> NOTRE MISSION : vous permettre de revendre vos produits le plus simplement possible et au meilleur prix. <br><br> - Pas d'annonce à créer <br> - Pas de prix à négocier <br> - Pas de frais cachés <br><br>COMMENT CA MARCHE ? <br><br> Choisissez votre produit à revendre dans votre suivi de commandes ou via le formulaire <br> Décrivez l'état de votre produit pour obtenir notre meilleure offre de reprise <br> Chosissez entre une carte cadeau immédiate ou différée <br> Expédiez votre produit gratuitement. <br> C'est simple et rapide !" 
                },
                message5 : {
    
                    question: "Comment puis-je vous contacter si j'ai une question ?",
                    answer: "Pour nous contacter c'est très simple, vous pouvez utiliser le chat disponible en bas à droite du site ou bien nous envoyer un mail à l'adresse {emailLink}"
                },
            },
            KYC: {
    
                message1 : {
    
                    question: "Pourquoi on me demande une pièce d'identité ?",
                    answer: "Dans le cadre de l'achat d'un produit par un professionnel à un particulier, il est obligatoire de pouvoir identifier le vendeur. <br> C'est une obligation légale. <br> Votre pièce d'identité permet au professionnel qui rachète votre produit de remplir son \"livre de police\". <br> <br> Sachez que votre pièce d'identité n'est pas transmise à notre partenaire : seules les informations nécessaires au livre de police sont envoyées. <br> <br> Si vous craignez les usurpations d'identité, n'hésitez pas à envoyer une image de votre pièce d'identité avec marqué GREENDID dessus en travers, afin d'être sûr que votre pièce d'identité ne pourra jamais être utilisée pour un autre service."
                },
                message2 : {
    
                    question: "GREENDID, c'est quoi ?",
                    answer: "GREENDID est la première plateforme de reventes de produits directement depuis votre suivi de commandes. <br><br> NOTRE MISSION : vous permettre de revendre vos produits le plus simplement possible et au meilleur prix. <br><br> - Pas d'annonce à créer <br> - Pas de prix à négocier <br> - Pas de frais cachés <br><br>COMMENT CA MARCHE ? <br><br> Choisissez votre produit à revendre dans votre suivi de commandes ou via le formulaire <br> Décrivez l'état de votre produit pour obtenir notre meilleure offre de reprise <br> Chosissez entre une carte cadeau immédiate ou différée <br> Expédiez votre produit gratuitement. <br> C'est simple et rapide !" 
                },
                message3 : {
    
                    question: "Comment puis-je vous contacter si j'ai une question ?",
                    answer: "Pour nous contacter c'est très simple, vous pouvez utiliser le chat disponible en bas à droite du site ou bien nous envoyer un mail à l'adresse {emailLink}"
                },
            },
            TRANSFER: {
    
                message1 : {
    
                    question: "Est-ce que je dois payer l'envoi du produit ?",
                    answer: "Non, vous ne payez pas l'envoi du produit. <br> Nous vous fournissons une étiquette à imprimer et à coller sur votre colis avant dépôt afin de l'expédier gratuitement."
                },
                message2 : {
    
                    question: "Comment puis-je vous contacter si j'ai une question ?",
                    answer: "Pour nous contacter c'est très simple, vous pouvez utiliser le chat disponible en bas à droite du site ou bien nous envoyer un mail à l'adresse {emailLink}"
                },
            },
            DELIVERY: {
    
                message1 : {
    
                    question: "Où dois-je déposer mon colis ?",
                    answer: "Au moment de la finalisation de la vente, vous recevez un mail vous indiquant où déposer votre appareil.  <br> <br> Si l'étiquette de transport fournie est une étiquette COLISSIMO : <a href=\"https://localiser.laposte.fr\" target=\"_blank\">[où déposer mon colis COLISSIMO ?]</a>  <br> <br> Si l'étiquette de transport fournie est une étiquette CHRONOPOST : <a href=\"http://www.chronopost.fr/expeditionAvancee/ounoustrouver.html\" target=\"_blank\">[Où déposer mon colis CHRONOPOST ?]</a>"
                },
                message2 : {
    
                    question: "Que se passe-t-il en cas de perte ou de vol du produit pendant le transport ?",
                    answer: "En cas de perte ou de vol pendant le transport, pas d'inquiétude si vous avez bien utilisé l'étiquette que nous vous avons fournie pour l'expédition. <br> Après enquête auprès du transporteur, vous aurez le droit à une carte cadeau à hauteur de la valeur de reprise de votre produit, <strong>jusqu'à un maximum de 300€</strong>. <br> <br> <strong>Une preuve d'achat de votre produit et son numéro de série ou l'IMEI pour les smartphones seront nécessaires pour bénéficier de cette compensation.</strong> <br> <strong>Pensez à bien le noter avant envoi.</strong> <br> <br> En cas de carte cadeau immédiate supérieure à 300€, la différence entre le montant de la carte cadeau déjà reçue et 300€ sera prélevée sur votre carte bancaire."
                },
                message3 : {
    
                    question: "Comment puis-je vous contacter si j'ai une question ?",
                    answer: "Pour nous contacter c'est très simple, vous pouvez utiliser le chat disponible en bas à droite du site ou bien nous envoyer un mail à l'adresse {emailLink}"
                },
            },
            CONFIRM: {
    
                message1 : {
    
                    question: "Où dois-je déposer mon colis ?",
                    answer: "Au moment de la finalisation de la vente, vous recevez un mail vous indiquant où déposer votre appareil.  <br> <br> Si l'étiquette de transport fournie est une étiquette COLISSIMO : <a href=\"https://localiser.laposte.fr\" target=\"_blank\">[où déposer mon colis COLISSIMO ?]</a>  <br> <br> Si l'étiquette de transport fournie est une étiquette CHRONOPOST : <a href=\"http://www.chronopost.fr/expeditionAvancee/ounoustrouver.html\" target=\"_blank\">[Où déposer mon colis CHRONOPOST ?]</a>"
                },
                message2 : {
    
                    question: "Comment dois-je emballer mon produit ?",
                    answer: "Votre produit s'apprête à vivre sa seconde vie ! Il est donc important de l'emballer soigneusement. <br> <br> Voici un petit guide pour vous accompagner dans la préparation de votre colis : <br> <br> 1 - Dépoussiérez / nettoyez votre produit <br>  Utilisez un chiffon (idéalement en micro-fibre) pour dépoussiérer et/ou supprimer les éventuelles tâches pouvant être présentes sur l'écran et/ou le clavier votre ordinateur portable, smartphone, tablette, console... <br> <br> 2 - Choisissez un emballage adapté et résistant <br> Sélectionnez votre emballage en fonction du matériel que vous allez expédier (dimensions, poids, fragilité). <br> Emballez les chargeurs séparément. <br> Si possible, emballez votre produit dans son emballage d'origine puis dans un carton qui servira de colis. <br> <br> 3 - Calez votre produit dans son carton <br> Afin de limiter au maximum les mouvements de votre produit pendant le transport, pensez à bien caler votre produit à l'intérieur du carton en rembourrant le fond, les côtés et la partie supérieure à l'aide de papier bulle, mousse, polystyrène, chips de calage ou laine de coton. <br> Vérifiez que le produit est bien calé en secouant doucement le carton : il ne doit pas bouger du tout. <br> <br> 4 - Fermez le carton  <br> A l'aide d'un adhésif large et résistant."
                },
                message3 : {
    
                    question: "Est-ce que je dois payer l'envoi du produit ?",
                    answer: "Non, vous ne payez pas l'envoi du produit. <br> Nous vous fournissons une étiquette à imprimer et à coller sur votre colis afin de l'expédier gratuitement.",
                },
                message4 : {
    
                    question: "Comment réinitialiser mon produit avant envoi ?",
                    answer:"Voici une liste de procédure pour réinitialier votre produit suivant sa catégorie et sa marque : <br> <br> Comment réinitialiser mon smartphone Android ? <br> Vous trouverez toutes les informations nécessaires en <a href=\"https://support.google.com/android/answer/6088915?hl=fr\" target=\"_blank\">[cliquant ici]</a> <br> <br> Comment effacer mes données sur mon smartphone Android ? <br> Vous trouverez toutes les informations nécessaires en <a href=\"https://support.google.com/android/answer/2819582?hl=fr\" target=\"_blank\">[cliquant ici]</a> <br> <br> Comment réinitialiser mon iPhone ? <br> Vous trouverez toutes les informations nécessaires en <a href=\"https://support.apple.com/fr-fr/HT201351\" target=\"_blank\">[cliquant ici]</a> <br> <br> Comment effacer mes données sur mon iPhone ? <br> Vous trouverez toutes les informations nécessaires en <a href=\"https://support.apple.com/fr-fr/HT201351\" target=\"_blank\">[cliquant ici]</a> <br> <br> Comment supprimer mon mot de passe sous windows ? <br> Vous trouverez la procédure pour supprimer votre mot de passe de session d'ordinateur en <a href=\"https://answers.microsoft.com/fr-fr/windows/forum/all/supprimer-mot-de-passe-%C3%A0-louverture-et/e0b8a8f2-36a9-4721-a0cc-ba838ddcfb9c\" target=\"_blank\">[cliquant ici]</a>"
                },
                message5 : {
    
                    question: "Que se passe-t-il en cas de perte ou de vol du produit pendant le transport ?",
                    answer: "En cas de perte ou de vol pendant le transport, pas d'inquiétude si vous avez bien utilisé l'étiquette que nous vous avons fournie pour l'expédition. <br> Après enquête auprès du transporteur, vous aurez le droit à un bon d'achat à hauteur de la valeur de reprise de votre produit, <strong>jusqu'à un maximum de 300€</strong>. <br> <br> <strong>Une preuve d'achat de votre produit et son numéro de série ou l'IMEI pour les smartphones seront nécessaires pour bénéficier de cette compensation.</strong> <br> <strong>Pensez à bien le noter avant envoi.</strong> <br> <br> En cas de bon d'achat immédiat supérieure à 300€, la différence entre le montant du bon d'achat déjà reçu et 300€ sera prélevée sur votre carte bancaire."
                },
                message6 : {
    
                    question: "Comment puis-je vous contacter si j'ai une question ?",
                    answer: "Pour nous contacter c'est très simple, vous pouvez utiliser le chat disponible en bas à droite du site ou bien nous envoyer un mail à l'adresse {emailLink}"
                },
            },
            SENT: {
    
                message1 : {
    
                    question: "Comment puis-je vous contacter si j'ai une question ?",
                    answer: "Pour nous contacter c'est très simple, vous pouvez utiliser le chat disponible en bas à droite du site ou bien nous envoyer un mail à l'adresse {emailLink}"
                },
            },
            TO_VALIDATE: {
    
                message1 : {
    
                    question: "Comment puis-je vous contacter si j'ai une question ?",
                    answer: "Pour nous contacter c'est très simple, vous pouvez utiliser le chat disponible en bas à droite du site ou bien nous envoyer un mail à l'adresse {emailLink}"
                },
            },
            STAND_BY: {
    
                message1 : {
    
                    question: "Comment puis-je vous contacter si j'ai une question ?",
                    answer: "Pour nous contacter c'est très simple, vous pouvez utiliser le chat disponible en bas à droite du site ou bien nous envoyer un mail à l'adresse {emailLink}"
                },
            },
            ACCEPTED: {
    
                message1 : {
    
                    question: "Pourquoi on me demande une pièce d'identité ?",
                    answer: "Dans le cadre de l'achat d'un produit par un professionnel à un particulier, il est obligatoire de pouvoir identifier le vendeur. <br> C'est une obligation légale. <br> Votre pièce d'identité permet au professionnel qui rachète votre produit de remplir son \"livre de police\". <br> <br> Sachez que votre pièce d'identité n'est pas transmise à notre partenaire : seules les informations nécessaires au livre de police sont envoyées. <br> <br> Si vous craignez les usurpations d'identité, n'hésitez pas à envoyer une image de votre pièce d'identité avec marqué GREENDID dessus en travers, afin d'être sûr que votre pièce d'identité ne pourra jamais être utilisée pour un autre service."
                },
                message2 : {
    
                    question: "Comment puis-je vous contacter si j'ai une question ?",
                    answer: "Pour nous contacter c'est très simple, vous pouvez utiliser le chat disponible en bas à droite du site ou bien nous envoyer un mail à l'adresse {emailLink}"
                },
            },
            COUNTER_OFFER: {
    
                message1 : {
    
                    question: "Comment puis-je vous contacter si j'ai une question ?",
                    answer: "Pour nous contacter c'est très simple, vous pouvez utiliser le chat disponible en bas à droite du site ou bien nous envoyer un mail à l'adresse {emailLink}"
                },
            },
            REFUSED: {
    
                message1 : {
    
                    question: "Comment puis-je vous contacter si j'ai une question ?",
                    answer: "Pour nous contacter c'est très simple, vous pouvez utiliser le chat disponible en bas à droite du site ou bien nous envoyer un mail à l'adresse {emailLink}"
                },
            },
            COUNTER_OFFER_ACCEPTED: {
    
                message1 : {
    
                    question: "Comment puis-je vous contacter si j'ai une question ?",
                    answer: "Pour nous contacter c'est très simple, vous pouvez utiliser le chat disponible en bas à droite du site ou bien nous envoyer un mail à l'adresse {emailLink}"
                },
            },
            COUNTER_OFFER_REFUSED: {
    
                message1 : {
    
                    question: "Comment puis-je vous contacter si j'ai une question ?",
                    answer: "Pour nous contacter c'est très simple, vous pouvez utiliser le chat disponible en bas à droite du site ou bien nous envoyer un mail à l'adresse {emailLink}"
                },
            },
        },
        fnac: {

            SEARCH_FORM: {
    
                message1 : {
    
                    question: 'Le service de revente est-il accessible à tout le monde ?',
                    answer: "Le service est accessible aux résidents en France. <br> La livraison de produits peut être possible pour d'autres pays. <br> Malheureusement, pour l'instant, le service n'est pas disponible pour les personnes ayant une adresse de livraison dans ces pays."
                },
                message2 : {
    
                    question: 'Est-ce que je peux revendre un appareil cassé ?',
                    answer: "Oui, les professionnels qui rachètent vos appareils sont tout à faits capables de les réparer, même si ils sont cassés. <br> Donc n'hésitez pas à regarder quel prix de reprise nous vous proposons, quel que soit l'état de votre appareil."
                },
                message3 : {
    
                    question: "GREENDID, c'est quoi ?",
                    answer: "GREENDID est la première plateforme de reventes de produits directement depuis votre suivi de commandes. <br><br> NOTRE MISSION : vous permettre de revendre vos produits le plus simplement possible et au meilleur prix. <br><br> - Pas d'annonce à créer <br> - Pas de prix à négocier <br> - Pas de frais cachés <br><br>COMMENT CA MARCHE ? <br><br> Choisissez votre produit à revendre dans votre suivi de commandes ou via le formulaire <br> Décrivez l'état de votre produit pour obtenir notre meilleure offre de reprise <br> Chosissez entre une carte cadeau immédiate ou différée <br> Expédiez votre produit gratuitement. <br> C'est simple et rapide !" 
                },
                message4 : {
    
                    question: "Comment puis-je vous contacter si j'ai une question ?",
                    answer: "Pour nous contacter c'est très simple, vous pouvez utiliser le chat disponible en bas à droite du site ou bien nous envoyer un mail à l'adresse {emailLink}"
                },
            },
            BASKET_CONDITIONS: {
    
                message1 : {
    
                    question: "Comment savoir quel état indiquer pour mon produit ?",
                    answer: "Afin de vous aider dans le choix de l'état de votre produit, vous pouvez cliquer sur l'icône \"?\" à côté de la question. <br> Vous obtiendrez des précisions sur chaque état."
                },
                message2 : {
    
                    question: "Est-ce que je peux revendre un appareil cassé ?",
                    answer: "Oui, les professionnels qui rachètent vos appareils sont tout à faits capables de les réparer, même si ils sont cassés, donc n'hésitez pas à regarder quel prix de reprise nous vous proposons, quel que soit l'état de votre appareil."
                },
                message3 : {
    
                    question: "GREENDID, c'est quoi ?",
                    answer: "GREENDID est la première plateforme de reventes de produits directement depuis votre suivi de commandes. <br><br> NOTRE MISSION : vous permettre de revendre vos produits le plus simplement possible et au meilleur prix. <br><br> - Pas d'annonce à créer <br> - Pas de prix à négocier <br> - Pas de frais cachés <br><br>COMMENT CA MARCHE ? <br><br> Choisissez votre produit à revendre dans votre suivi de commandes ou via le formulaire <br> Décrivez l'état de votre produit pour obtenir notre meilleure offre de reprise <br> Chosissez entre une carte cadeau immédiate ou différée <br> Expédiez votre produit gratuitement. <br> C'est simple et rapide !" 
                },
                message4 : {
    
                    question: "Comment puis-je vous contacter si j'ai une question ?",
                    answer: "Pour nous contacter c'est très simple, vous pouvez utiliser le chat disponible en bas à droite du site ou bien nous envoyer un mail à l'adresse {emailLink}"
                },
            },
            SIGN_IN: {
    
                message1 : {
    
                    question: "Pourquoi est-ce que je dois créer un compte GREENDID ?",
                    answer: "Nous vous demandons de créer un compte GREENDID afin d'éviter tout transfert d'informations personnelles. <br> A la création du compte GREENDID, nous vous demandons uniquement les informations nécessaires à la revente de votre produit. <br> Ainsi vous validez bien toutes les informations que vous nous fournissez."
                },
                message2 : {
    
                    question: "GREENDID, c'est quoi ?",
                    answer: "GREENDID est la première plateforme de reventes de produits directement depuis votre suivi de commandes. <br><br> NOTRE MISSION : vous permettre de revendre vos produits le plus simplement possible et au meilleur prix. <br><br> - Pas d'annonce à créer <br> - Pas de prix à négocier <br> - Pas de frais cachés <br><br>COMMENT CA MARCHE ? <br><br> Choisissez votre produit à revendre dans votre suivi de commandes ou via le formulaire <br> Décrivez l'état de votre produit pour obtenir notre meilleure offre de reprise <br> Chosissez entre une carte cadeau immédiate ou différée <br> Expédiez votre produit gratuitement. <br> C'est simple et rapide !" 
                },
                message3 : {
    
                    question: "Comment puis-je vous contacter si j'ai une question ?",
                    answer: "Pour nous contacter c'est très simple, vous pouvez utiliser le chat disponible en bas à droite du site ou bien nous envoyer un mail à l'adresse {emailLink}"
                },
            },
            COUPON_TYPE: {
    
                message1 : {
    
                    question: "Que puis-je acheter avec ma carte cadeau ?",
                    answer: "Une fois la reprise validée, vous recevrez votre carte cadeau par mail. <br> Votre carte cadeau sera valable 12 mois sur sur tous les produits hors marketplace dans les magasins Fnac, Darty et sur fnac.com en France métropolitaine."
                },
                message2 : {
    
                    question: "Quelle différence entre la carte cadeau immédiate et la carte cadeau différée ?",
                    answer: "Vous pouvez choisir de recevoir votre carte cadeau avant même d'expédier le produit revendu. <br> Pour cela, il faut choisir la carte cadeau immédiate. <br> On vous demande juste une empreinte de votre carte bancaire au cas où vous changeriez d'avis. <br> Cela vous permet d'acheter un nouveau produit avant d'expédier le produit revendu. <br> <br> Si vous n'êtes pas pressé, vous pouvez choisir la carte cadeau différée. <br> Vous la recevrez après validation de votre produit par notre partenaire."
                },
                message3 : {
    
                    question: "Comment fonctionnent les bonus / codes promo ?",
                    answer: "A certaines périodes, en revendant l'un de vos produits, vous aurez le droit de choisir un bonus sous la forme d'un code promo permettant d'appliquer une réduction lors de l'achat d'un nouveau produit. <br> <br> Ces codes promo ne sont valables que pendant une certaine période et uniquement sur une liste définie de produits. <br> <br> Les conditions d'obtention et d'application de ces codes promos sont expliquées lors de chaque opération. <br> <br> Ces codes promo sont cumulables avec l'utilisation d'une ou plusieurs cartes cadeaux mais ne sont pas cumulables avec l'utilisation d'autres codes promo. <br> Ils permettent d'obtenir une réduction sur l'achat d'un futur produit."
                },
                message4 : {
    
                    question: "GREENDID, c'est quoi ?",
                    answer: "GREENDID est la première plateforme de reventes de produits directement depuis votre suivi de commandes. <br><br> NOTRE MISSION : vous permettre de revendre vos produits le plus simplement possible et au meilleur prix. <br><br> - Pas d'annonce à créer <br> - Pas de prix à négocier <br> - Pas de frais cachés <br><br>COMMENT CA MARCHE ? <br><br> Choisissez votre produit à revendre dans votre suivi de commandes ou via le formulaire <br> Décrivez l'état de votre produit pour obtenir notre meilleure offre de reprise <br> Chosissez entre une carte cadeau immédiate ou différée <br> Expédiez votre produit gratuitement. <br> C'est simple et rapide !" 
                },
                message5 : {
    
                    question: "Comment puis-je vous contacter si j'ai une question ?",
                    answer: "Pour nous contacter c'est très simple, vous pouvez utiliser le chat disponible en bas à droite du site ou bien nous envoyer un mail à l'adresse {emailLink}"
                },
            },
            KYC: {
    
                message1 : {
    
                    question: "Pourquoi on me demande une pièce d'identité ?",
                    answer: "Dans le cadre de l'achat d'un produit par un professionnel à un particulier, il est obligatoire de pouvoir identifier le vendeur. <br> C'est une obligation légale. <br> Votre pièce d'identité permet au professionnel qui rachète votre produit de remplir son \"livre de police\". <br> <br> Sachez que votre pièce d'identité n'est pas transmise à notre partenaire : seules les informations nécessaires au livre de police sont envoyées. <br> <br> Si vous craignez les usurpations d'identité, n'hésitez pas à envoyer une image de votre pièce d'identité avec marqué GREENDID dessus en travers, afin d'être sûr que votre pièce d'identité ne pourra jamais être utilisée pour un autre service."
                },
                message2 : {
    
                    question: "GREENDID, c'est quoi ?",
                    answer: "GREENDID est la première plateforme de reventes de produits directement depuis votre suivi de commandes. <br><br> NOTRE MISSION : vous permettre de revendre vos produits le plus simplement possible et au meilleur prix. <br><br> - Pas d'annonce à créer <br> - Pas de prix à négocier <br> - Pas de frais cachés <br><br>COMMENT CA MARCHE ? <br><br> Choisissez votre produit à revendre dans votre suivi de commandes ou via le formulaire <br> Décrivez l'état de votre produit pour obtenir notre meilleure offre de reprise <br> Chosissez entre une carte cadeau immédiate ou différée <br> Expédiez votre produit gratuitement. <br> C'est simple et rapide !" 
                },
                message3 : {
    
                    question: "Comment puis-je vous contacter si j'ai une question ?",
                    answer: "Pour nous contacter c'est très simple, vous pouvez utiliser le chat disponible en bas à droite du site ou bien nous envoyer un mail à l'adresse {emailLink}"
                },
            },
            TRANSFER: {
    
                message1 : {
    
                    question: "Est-ce que je dois payer l'envoi du produit ?",
                    answer: "Non, vous ne payez pas l'envoi du produit. <br> Nous vous fournissons une étiquette à imprimer et à coller sur votre colis avant dépôt afin de l'expédier gratuitement."
                },
                message2 : {
    
                    question: "Comment puis-je vous contacter si j'ai une question ?",
                    answer: "Pour nous contacter c'est très simple, vous pouvez utiliser le chat disponible en bas à droite du site ou bien nous envoyer un mail à l'adresse {emailLink}"
                },
            },
            DELIVERY: {
    
                message1 : {
    
                    question: "Où dois-je déposer mon colis ?",
                    answer: "Au moment de la finalisation de la vente, vous recevez un mail vous indiquant où déposer votre appareil.  <br> <br> Si l'étiquette de transport fournie est une étiquette COLISSIMO : <a href=\"https://localiser.laposte.fr\" target=\"_blank\">[où déposer mon colis COLISSIMO ?]</a>  <br> <br> Si l'étiquette de transport fournie est une étiquette CHRONOPOST : <a href=\"http://www.chronopost.fr/expeditionAvancee/ounoustrouver.html\" target=\"_blank\">[Où déposer mon colis CHRONOPOST ?]</a>"
                },
                message2 : {
    
                    question: "Que se passe-t-il en cas de perte ou de vol du produit pendant le transport ?",
                    answer: "En cas de perte ou de vol pendant le transport, pas d'inquiétude si vous avez bien utilisé l'étiquette que nous vous avons fournie pour l'expédition. <br> Après enquête auprès du transporteur, vous aurez le droit à une carte cadeau à hauteur de la valeur de reprise de votre produit, <strong>jusqu'à un maximum de 300€</strong>. <br> <br> <strong>Une preuve d'achat de votre produit et son numéro de série ou l'IMEI pour les smartphones seront nécessaires pour bénéficier de cette compensation.</strong> <br> <strong>Pensez à bien le noter avant envoi.</strong> <br> <br> En cas de carte cadeau immédiate supérieure à 300€, la différence entre le montant de la carte cadeau déjà reçue et 300€ sera prélevée sur votre carte bancaire."
                },
                message3 : {
    
                    question: "Comment puis-je vous contacter si j'ai une question ?",
                    answer: "Pour nous contacter c'est très simple, vous pouvez utiliser le chat disponible en bas à droite du site ou bien nous envoyer un mail à l'adresse {emailLink}"
                },
            },
            CONFIRM: {
    
                message1 : {
    
                    question: "Où dois-je déposer mon colis ?",
                    answer: "Au moment de la finalisation de la vente, vous recevez un mail vous indiquant où déposer votre appareil.  <br> <br> Si l'étiquette de transport fournie est une étiquette COLISSIMO : <a href=\"https://localiser.laposte.fr\" target=\"_blank\">[où déposer mon colis COLISSIMO ?]</a>  <br> <br> Si l'étiquette de transport fournie est une étiquette CHRONOPOST : <a href=\"http://www.chronopost.fr/expeditionAvancee/ounoustrouver.html\" target=\"_blank\">[Où déposer mon colis CHRONOPOST ?]</a>"
                },
                message2 : {
    
                    question: "Comment dois-je emballer mon produit ?",
                    answer: "Votre produit s'apprête à vivre sa seconde vie ! Il est donc important de l'emballer soigneusement. <br> <br> Voici un petit guide pour vous accompagner dans la préparation de votre colis : <br> <br> 1 - Dépoussiérez / nettoyez votre produit <br>  Utilisez un chiffon (idéalement en micro-fibre) pour dépoussiérer et/ou supprimer les éventuelles tâches pouvant être présentes sur l'écran et/ou le clavier votre ordinateur portable, smartphone, tablette, console... <br> <br> 2 - Choisissez un emballage adapté et résistant <br> Sélectionnez votre emballage en fonction du matériel que vous allez expédier (dimensions, poids, fragilité). <br> Emballez les chargeurs séparément. <br> Si possible, emballez votre produit dans son emballage d'origine puis dans un carton qui servira de colis. <br> <br> 3 - Calez votre produit dans son carton <br> Afin de limiter au maximum les mouvements de votre produit pendant le transport, pensez à bien caler votre produit à l'intérieur du carton en rembourrant le fond, les côtés et la partie supérieure à l'aide de papier bulle, mousse, polystyrène, chips de calage ou laine de coton. <br> Vérifiez que le produit est bien calé en secouant doucement le carton : il ne doit pas bouger du tout. <br> <br> 4 - Fermez le carton  <br> A l'aide d'un adhésif large et résistant."
                },
                message3 : {
    
                    question: "Est-ce que je dois payer l'envoi du produit ?",
                    answer: "Non, vous ne payez pas l'envoi du produit. <br> Nous vous fournissons une étiquette à imprimer et à coller sur votre colis afin de l'expédier gratuitement.",
                },
                message4 : {
    
                    question: "Comment réinitialiser mon produit avant envoi ?",
                    answer:"Voici une liste de procédure pour réinitialier votre produit suivant sa catégorie et sa marque : <br> <br> Comment réinitialiser mon smartphone Android ? <br> Vous trouverez toutes les informations nécessaires en <a href=\"https://support.google.com/android/answer/6088915?hl=fr\" target=\"_blank\">[cliquant ici]</a> <br> <br> Comment effacer mes données sur mon smartphone Android ? <br> Vous trouverez toutes les informations nécessaires en <a href=\"https://support.google.com/android/answer/2819582?hl=fr\" target=\"_blank\">[cliquant ici]</a> <br> <br> Comment réinitialiser mon iPhone ? <br> Vous trouverez toutes les informations nécessaires en <a href=\"https://support.apple.com/fr-fr/HT201351\" target=\"_blank\">[cliquant ici]</a> <br> <br> Comment effacer mes données sur mon iPhone ? <br> Vous trouverez toutes les informations nécessaires en <a href=\"https://support.apple.com/fr-fr/HT201351\" target=\"_blank\">[cliquant ici]</a> <br> <br> Comment supprimer mon mot de passe sous windows ? <br> Vous trouverez la procédure pour supprimer votre mot de passe de session d'ordinateur en <a href=\"https://answers.microsoft.com/fr-fr/windows/forum/all/supprimer-mot-de-passe-%C3%A0-louverture-et/e0b8a8f2-36a9-4721-a0cc-ba838ddcfb9c\" target=\"_blank\">[cliquant ici]</a>"
                },
                message5 : {
    
                    question: "Que se passe-t-il en cas de perte ou de vol du produit pendant le transport ?",
                    answer: "En cas de perte ou de vol pendant le transport, pas d'inquiétude si vous avez bien utilisé l'étiquette que nous vous avons fournie pour l'expédition. <br> Après enquête auprès du transporteur, vous aurez le droit à une carte cadeau à hauteur de la valeur de reprise de votre produit, <strong>jusqu'à un maximum de 300€</strong>. <br> <br> <strong>Une preuve d'achat de votre produit et son numéro de série ou l'IMEI pour les smartphones seront nécessaires pour bénéficier de cette compensation.</strong> <br> <strong>Pensez à bien le noter avant envoi.</strong> <br> <br> En cas de carte cadeau immédiate supérieure à 300€, la différence entre le montant de la carte cadeau déjà reçue et 300€ sera prélevée sur votre carte bancaire."
                },
                message6 : {
    
                    question: "Comment puis-je vous contacter si j'ai une question ?",
                    answer: "Pour nous contacter c'est très simple, vous pouvez utiliser le chat disponible en bas à droite du site ou bien nous envoyer un mail à l'adresse {emailLink}"
                },
            },
            SENT: {
    
                message1 : {
    
                    question: "Comment puis-je vous contacter si j'ai une question ?",
                    answer: "Pour nous contacter c'est très simple, vous pouvez utiliser le chat disponible en bas à droite du site ou bien nous envoyer un mail à l'adresse {emailLink}"
                },
            },
            TO_VALIDATE: {
    
                message1 : {
    
                    question: "Comment puis-je vous contacter si j'ai une question ?",
                    answer: "Pour nous contacter c'est très simple, vous pouvez utiliser le chat disponible en bas à droite du site ou bien nous envoyer un mail à l'adresse {emailLink}"
                },
            },
            STAND_BY: {
    
                message1 : {
    
                    question: "Comment puis-je vous contacter si j'ai une question ?",
                    answer: "Pour nous contacter c'est très simple, vous pouvez utiliser le chat disponible en bas à droite du site ou bien nous envoyer un mail à l'adresse {emailLink}"
                },
            },
            ACCEPTED: {
    
                message1 : {
    
                    question: "Pourquoi on me demande une pièce d'identité ?",
                    answer: "Dans le cadre de l'achat d'un produit par un professionnel à un particulier, il est obligatoire de pouvoir identifier le vendeur. <br> C'est une obligation légale. <br> Votre pièce d'identité permet au professionnel qui rachète votre produit de remplir son \"livre de police\". <br> <br> Sachez que votre pièce d'identité n'est pas transmise à notre partenaire : seules les informations nécessaires au livre de police sont envoyées. <br> <br> Si vous craignez les usurpations d'identité, n'hésitez pas à envoyer une image de votre pièce d'identité avec marqué GREENDID dessus en travers, afin d'être sûr que votre pièce d'identité ne pourra jamais être utilisée pour un autre service."
                },
                message2 : {
    
                    question: "Comment puis-je vous contacter si j'ai une question ?",
                    answer: "Pour nous contacter c'est très simple, vous pouvez utiliser le chat disponible en bas à droite du site ou bien nous envoyer un mail à l'adresse {emailLink}"
                },
            },
            COUNTER_OFFER: {
    
                message1 : {
    
                    question: "Comment puis-je vous contacter si j'ai une question ?",
                    answer: "Pour nous contacter c'est très simple, vous pouvez utiliser le chat disponible en bas à droite du site ou bien nous envoyer un mail à l'adresse {emailLink}"
                },
            },
            REFUSED: {
    
                message1 : {
    
                    question: "Comment puis-je vous contacter si j'ai une question ?",
                    answer: "Pour nous contacter c'est très simple, vous pouvez utiliser le chat disponible en bas à droite du site ou bien nous envoyer un mail à l'adresse {emailLink}"
                },
            },
            COUNTER_OFFER_ACCEPTED: {
    
                message1 : {
    
                    question: "Comment puis-je vous contacter si j'ai une question ?",
                    answer: "Pour nous contacter c'est très simple, vous pouvez utiliser le chat disponible en bas à droite du site ou bien nous envoyer un mail à l'adresse {emailLink}"
                },
            },
            COUNTER_OFFER_REFUSED: {
    
                message1 : {
    
                    question: "Comment puis-je vous contacter si j'ai une question ?",
                    answer: "Pour nous contacter c'est très simple, vous pouvez utiliser le chat disponible en bas à droite du site ou bien nous envoyer un mail à l'adresse {emailLink}"
                },
            },
        },
        jerevends: {

            SEARCH_FORM: {
    
            },
            BASKET_CONDITIONS: {
    
            },
            SIGN_IN: {
    
            },
            COUPON_TYPE: {
    
            },
            SENT: {
    
            },
            TO_VALIDATE: {
    
            },
            STAND_BY: {
    
            },
            ACCEPTED: {

            },
            COUNTER_OFFER: {
    
            },
            REFUSED: {
    
            },
            COUNTER_OFFER_ACCEPTED: {
    
            },
            COUNTER_OFFER_REFUSED: {
            
            },
            KYC: {

            },
            TRANSFER: {
                
            }
        },
        greenfox: {

            SEARCH_FORM: {
    
            },
            BASKET_CONDITIONS: {
    
            },
            SIGN_IN: {
    
            },
            COUPON_TYPE: {
    
            },
            KYC: {
    
            },
            TRANSFER: {
    
            },
            DELIVERY: {
    
            },
            CONFIRM: {
    
            },
            SENT: {
    
            },
            TO_VALIDATE: {
    
            },
            STAND_BY: {
    
            },
            ACCEPTED: {
    
            },
            COUNTER_OFFER: {
    
            },
            REFUSED: {
    
            },
            COUNTER_OFFER_ACCEPTED: {
    
            },
            COUNTER_OFFER_REFUSED: {
    
            },
        },
        carrefour: {

            SEARCH_FORM: {
    
            },
            BASKET_CONDITIONS: {
    
            },
            SIGN_IN: {
    
            },
            COUPON_TYPE: {
    
            },
            KYC: {
    
            },
            TRANSFER: {
    
            },
            DELIVERY: {
    
            },
            CONFIRM: {
    
            },
            SENT: {
    
            },
            TO_VALIDATE: {
    
            },
            STAND_BY: {
    
            },
            ACCEPTED: {
    
            },
            COUNTER_OFFER: {
    
            },
            REFUSED: {
    
            },
            COUNTER_OFFER_ACCEPTED: {
    
            },
            COUNTER_OFFER_REFUSED: {
    
            },
        },
        reprise: {

            SEARCH_FORM: {
    
            },
            BASKET_CONDITIONS: {
    
            },
            SIGN_IN: {
    
            },
            COUPON_TYPE: {
    
            },
            KYC: {
    
            },
            TRANSFER: {
    
            },
            DELIVERY: {
    
            },
            CONFIRM: {
    
            },
            SENT: {
    
            },
            TO_VALIDATE: {
    
            },
            STAND_BY: {
    
            },
            ACCEPTED: {
    
            },
            COUNTER_OFFER: {
    
            },
            REFUSED: {
    
            },
            COUNTER_OFFER_ACCEPTED: {
    
            },
            COUNTER_OFFER_REFUSED: {
    
            },
        }
    },
    MERCHANT: {
        IFRAME: {
            3919794380: {
                ORDER_VALIDATED: 'Votre reprise est validée !',
                ORDER_VALIDATED_NEXT: 'Finaliser ma commande'
            },
            7764956693: {
                ORDER_VALIDATED: '<img src="/assets/img/darty-reprise/icon-tick-small.svg" /> <span style="color:#007A38;">Vendu ! Reprise validée</span>',
                ORDER_VALIDATED_NEXT: 'Passer au paiement'
            }
        }
    }
}
