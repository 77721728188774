<script type="text/ecmascript-6">

import DashboardLayout from '../../layouts/Dashboard.vue'
import settingsService from '../../services/settings'
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

export default {
    components: {DashboardLayout, VuePhoneNumberInput},
    data() {

        return {
            settings: null,
            isPhoneNumberValid: false,
            isSaveLoading: false,
            message: '',
            error: ''
        }
    },
    created() {

        this.loadSettings()
    },
    methods: {

        async loadSettings() {

            let response = await settingsService.get()
            this.settings = response.data
        },
        async save() {

            this.message = ''
            this.isSaveLoading = true;
            try {

                await settingsService.update(this.phoneNumber)
                this.message = 'Les paramètres ont été sauvegardés.'
            }
            catch (err) {

                this.error = 'Une erreur est survenue.'
            }

            this.isSaveLoading = false;
        },
        updatePhoneNumber(data) {

            this.phoneNumber = data.e164
            this.isPhoneNumberValid = data.isValid
        }
    },
    computed: {

        phoneTranslations() {

            return {

                countrySelectorLabel: 'Code pays',
                countrySelectorError: 'Choisir un pays',
                phoneNumberLabel: 'Numéro de téléphone',
                example: 'Exemple :'
            }
        }
    }
}
</script>
<template>
    <dashboard-layout>
        <h3>Paramètres</h3>
        <div v-if="settings != null" class="row">
            <div class="col-md-6">
                <div v-if="message != ''" class="alert alert-info" role="alert">{{message}}</div>
                <div v-if="error != ''" class="alert alert-danger" role="alert">{{error}}</div>
                <form @submit.prevent="save">
                    <div class='form-group'>
                        <label>Numéro de téléphone : </label>
                        <vue-phone-number-input @update="updatePhoneNumber" :translations="phoneTranslations" v-model="settings.phone_number" :only-countries="['FR', 'BE']" :default-country-code="'FR'" />
                    </div>
                    <button :disabled="!isPhoneNumberValid" type="submit" class="btn btn-primary mt-2">
                        <span v-if="isSaveLoading" class="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                        Enregistrer
                    </button>
                </form>
            </div>
        </div>
    </dashboard-layout>
</template>