<script type="text/ecmascript-6">

import BasketLayout from '../../layouts/iframe/Basket.vue'
import SidePanelHeader from '../../components/iframe/SidePanelHeader.vue';
import Btn from '@/components/iframe/Button.vue'
import merchantMixin from '@/mixins/merchant'
import mediaMixin from '@/mixins/media'
import basketMixin from '@/mixins/basket'
import iframeMixin from '@/mixins/iframe'
import orderService from '@/services/order'
import basketService from '@/services/basket'
import authService from '@/services/auth.js'

export default {

    components: {BasketLayout, SidePanelHeader, Btn}, 
    mixins: [merchantMixin, mediaMixin, basketMixin, iframeMixin],
    data() {
        return {
            isLoading: false,
            bonusSelected: null,
            offerValidated: false,
            error: ''
        }
    },
    methods: {
        async validateOffer(basketPublicId) {

            const skusArray = this.$store.getters.skus == null || this.$store.getters.skus == undefined || this.$store.getters.skus.length == 0 ? [] : this.$store.getters.skus
            
            try {

                console.log('moreTime', this.$store.getters.moreTime)
                let response = await basketService.validateOffer(basketPublicId, skusArray, this.$store.getters.moreTime)
                this.$store.commit('setBasket', response.data)
                this.offerValidated = true
            }
            catch (error) {

                if (error.response.data.message == 'CONDITION_NOT_ACCEPTED') {

                    this.conditionAccepted = false
                }
            }

            this.bonusDisplayed()
        },
        async submitSSOAuth() {

            this.isLoading = true

            let redirectUri = window.location.href.replace(window.location.search, '');
            let response = await authService.ssoAuth(this.basket.public_id, null, redirectUri, this.merchant.id, 'fnac')
            
            if (response.data) {
                
                window.location.href = response.data
            }
        },
        async createOrder() {

            this.isLoading = true

            try {

                let merchantUserId = this.$store.getters.merchantUserId != undefined ? this.$store.getters.merchantUserId : this.basket.merchant_user_id

                let response = await orderService.create(
                    this.basket.product_id, 
                    this.basket.merchant_id,
                    this.basket.merchant_product_id,
                    merchantUserId,
                    this.basket.merchant_order_id,
                    this.basket.merchant_product_img,
                    this.basket.merchant_order_date,
                    this.basket.merchant_product_price,
                    this.basket.conditions,
                    this.basket.public_id,
                    this.basket.invoice_filename,
                    this.bonusSelected != undefined ? this.bonusSelected : null,
                    this.basket.offer_template != undefined ? this.basket.offer_template.id : null,
                    this.basket.offer_specs != undefined ? this.basket.offer_specs.id : null,
                    this.basket.offer_specs != undefined ? this.basket.offer_specs.specs : null,
                    this.basket.type,
                    this.selectedPaymentType,
                    this.basket.source_site
                )

                this.order = response.data

                if (this.order.coupon_type == 'IMMEDIAT') {

                    this.$router.push({name: 'cardFormIframe', params:{id: this.order.public_id}})
                }
                else {

                    this.$router.push({name: 'kycIframe', params:{id: this.order.public_id}})
                }
            }
            catch (error) {

                if (error.response.status == 401) {

                    if (this.isLogged !== 'true') {

                        if (this.merchant.has_sso) {

                            await this.submitSSOAuth()
                        }
                        else {

                            this.$router.push({name: 'loginIframe', query: {returnUrl: this.returnPath}})
                        }
                    }
                    else {

                        this.error = 'Une erreur est survenue. Veuillez contacter le support'
                    }
                }
                else if (error.response.status == 500) {

                    this.error = 'Une erreur est survenue. Veuillez contacter le support'
                }

                this.isLoading = false
            }
        },
        bonusDisplayed() {

            let maxAmount = 0;
            let maxAmountBonuses = [];

            if (this.basket.bonus.length < 1 ) return; 
            if (this.basket.bonus.length === 1 ) {

                this.bonusSelected = this.basket.bonus[0]
            }
            if (this.basket.bonus.length > 1 ) {

                this.basket.bonus.forEach(bonus => {

                    if (bonus.bonus_amount > maxAmount) {

                        maxAmount = bonus.bonus_amount;
                        maxAmountBonuses = [bonus];
                    } 
                    else if (bonus.bonus_amount === maxAmount) {

                        maxAmountBonuses.push(bonus);
                    }
                });

                this.bonusSelected = maxAmountBonuses[Math.floor(Math.random() * maxAmountBonuses.length)];
            }
        }
    },
    computed: {
        subdomain() {

            return window.location.hostname.split('.')[0]
        },
        readyToValidate() {

            return this.isBasketLoaded && this.isSkuLoaded
        },
        isLogged() {

            if (this.$route.query.logged != undefined) return this.$route.query.logged

            return this.$route.query.isLogged
        },
        returnUrl() {

            return window.location.href.split('?')[0]
        },
        returnPath() {

            return this.$route.path
        },
        displayBypass() {

            return process.env.VUE_APP_ENVIRONMENT != 'production' && this.merchant && this.merchant.has_sso
        },
        selectedPaymentType() {

            if (this.merchant == undefined) return null

            if (this.merchant.has_cash) return 'cash'

            return 'coupon'
        },
        hasPromoCode() {

            if (this.merchant.id == 7764956693) return false

            return true
        }
    },
    watch: {
        readyToValidate(val) {

            if (val) {

                this.validateOffer(this.basket.public_id)
            }
        },
        offerValidated(val) {

            if (val == true && this.isLogged == 'true') {

                this.createOrder()
            }
        },
        isBasketLoaded(val) {

            if (val) {

                this.$tracking.pageView('Finalize Order', 'Iframe Basket', {'Basket ID': this.basketId, 'Basket Product Type': this.basket.product_type, 'SKUS': this.$store.getters.skus, 'Is Logged': this.isLogged})
            }
        }
    },
    created() {

        this.$store.commit('setSkus', this.$route.query['skus[]'])
        this.$store.commit('setMerchantUserId', this.$route.query.umid)
        this.$store.commit('setOptions', this.$route.query.options)
    }
}
</script>
<template>
    <basket-layout>
        <side-panel-header :title="''" />
        <div v-if="merchant && isBasketLoaded">
            <div class="iframe-content">
                <div id="finalize-content">
                    <div id="content-header">
                        <img id="content-header-img" height="60px" :src="getLocalImage('seconde-vie.svg', merchantCss)" alt="">
                        <h2>Finalisez votre reprise</h2>
                        <h3 id="content-header-offer" >Offre de reprise : <span id="content-header-price" >{{basket.product_offer.price | currency}}</span></h3>
                        <p id="content-header-title" >{{basket.product_offer.sku}}</p>
                    </div>
                    <div v-if="bonusSelected" id="bonus-container">
                        <div id="left-part" ></div>
                        <div id="bonus-text">
                            <div v-if="bonusSelected.bonus_amount > 0">
                                <span class="bonus-price">+ {{bonusSelected.bonus_amount / 100}} € de bonus</span>
                                <span v-if="hasPromoCode"> via code promo</span> pour l’achat d’un {{bonusSelected.name}}
                            </div>
                            <div v-if="bonusSelected.can_renew_preauth == 1">
                                <span class="bonus-price">+15 jours</span> <span>pour envoyer votre produit pour l’achat d’un {{bonusSelected.name}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="bonus-legal">
                        <small>Offre exclusive web, prix non garanti en magasin.</small>
                        <small v-if="bonusSelected"><br/>Non cumulable avec un autre code promo.</small>
                    </div>
                    <div id="next-step-container">
                        <p class="alert" v-if="isLogged === 'false'">Une erreur lors de l'authentification est survenue</p>
                        <p class="alert" v-if="error != ''">{{ error }}</p>
                        <h3>Voici les 3 dernières étapes :</h3>
                        <div v-if="merchant.can_have_immediat_coupon" class="next-step-row">
                            <div class="next-step-row-header">
                                <img :src="getLocalImage('icon-cb.svg', merchantCss)" class="next-step-icon" />
                                <p class="bold">Demande de pré-autorisation bancaire</p>
                            </div>
                            <p>Cela va vous permettre de bénéficier immédiatement de votre carte cadeau et de vous faire une contre-offre de reprise si le diagnostic n’est pas conforme à votre déclaration.</p>
                        </div>
                        <div v-if="merchant.has_cash" class="next-step-row">
                            <div class="next-step-row-header">
                                <img :src="getLocalImage('icon-cb.svg', merchantCss)" class="next-step-icon" />
                                <p class="bold">Remboursement par virement bancaire</p>
                            </div>
                            <p>Le virement de votre offre reprise sera effectué sous 14 jours après validation de votre produit et de votre IBAN.</p>
                        </div>
                        <div class="next-step-row">
                            <div class="next-step-row-header">
                                <img :src="getLocalImage('icon-identity.svg', merchantCss)" class="next-step-icon" />
                                <p class="bold">Vérification de votre identité</p>
                            </div>
                            <p>Grâce à votre pièce d’identité, nous pourrons éditer le bon de cession.</p>
                        </div>
                        <div class="next-step-row">
                            <div class="next-step-row-header">
                                <img :src="getLocalImage('icon-transfer.svg', merchantCss)" class="next-step-icon" />
                                <p class="bold">Signature du bon de cession</p>
                            </div>
                            <p>Il permet de garantir la légalité de la transaction.</p>
                        </div>
                    </div>
                    <p>
                        En continuant, vous acceptez les <a :href="getCguLink(subdomain)" style="text-decoration:underline" target="_blank">Conditions Générales de reprises</a> <img src="/assets/img/external-link.svg" />
                        <br/>
                        Vos données, dans le cadre de la reprise, sont traitées par Greendid en qualité de co-responsable.
                        <br/>
                        Pour plus d'informations et pour exercer vos droits vous pouvez consulter la <a :href="getPrivacyPolicyLink(subdomain)" style="text-decoration:underline" target="_blank">Politique De Données Personnelles</a>. <img src="/assets/img/external-link.svg" />
                    </p>
                    <div id="btn-wrapper" >
                        <btn class="btn-iframe-dark" @click="createOrder()" :text="'Oui'" :color="'white'" :backgroundColor="`var(--iframe-color-black)`" :isLoading="isLoading"/>  
                        <btn v-if="displayBypass" @click="$router.push({name:'loginIframe', query:{returnUrl: returnPath}})" :text="'Bypass'" :color="'white'" :backgroundColor="`var(--iframe-color-black)`" :isLoading="isLoading"/>  
                    </div>                    
                </div>
            </div>
        </div>
    </basket-layout>
</template>
<style scoped>
a {
    cursor: pointer;
}

#next-step-container {
    margin:30px 0px 20px 0px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

#next-step-container h3 {
    margin-bottom:10px;
}

.next-step-icon {
    min-width: 20px;
    min-height: 20px;
}

.next-step-row-header {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 10px;
}

.next-step-row {

    margin-bottom:10px;
}

.bold {

    font-weight: var(--iframe-font-weight-bold);
    color: black
}

.alert {

   color: red;
   font-weight: bold;
}

#finalize-section, #finalize-content {
    display: flex;
    flex-direction: column;
}

#finalize-section{
    width: 100%;
    height: calc(100vh - 100px);
    padding:0 32px 32px 32px;
}

#btn-wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    margin-top:40px;
}

.bonus-price {
    color:var(--iframe-color-price);
    font-weight: var(--iframe-font-weight-bold);
}

#bonus-container #bonus-text {

    margin:10px;
}

#left-part {

    background-color: #0C7203;
    width: 4px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

#content-header-title {

color: var(--iframe-border-grey-dark);
}

#bonus-container {

    border: 1px var(--iframe-border-grey-light) solid;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    margin-top: 10px;
}

#content-header-img {
    height: 60px !important;
    margin-bottom: 20px;
}

.bonus-legal {

    color: var(--iframe-font-color-light);
    margin-top: 5px;
}

#content-header {
    
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top:30px;
}

#content-header-price {

    color: var(--iframe-color-price);
    font-size: var(--iframe-font-size-h2);
    font-weight: var(--iframe-font-weight-bold);
}

</style>

