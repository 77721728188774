export default {
    state: {
        source: null,
        merchantUserId: null,
        skus: [],
        moreTime: 0
    },
    getters: {
        source: state => { return state.source },
        merchantUserId: state => { return state.merchantUserId },
        skus: state => { return state.skus },
        moreTime: state => { return state.moreTime }
    },
    mutations: {
        setSource (state, source) {
            
            if (source == undefined) {

                source = localStorage.getItem('source')
                state.source = source
            }
            else {

                localStorage.setItem('source', source)
                state.source = source
            }
        },
        setMerchantUserId (state, merchantUserId) {
            
            if (merchantUserId == undefined) {

                merchantUserId = localStorage.getItem('merchantUserId')
                state.merchantUserId = merchantUserId
            }
            else {

                localStorage.setItem('merchantUserId', merchantUserId)
                state.merchantUserId = merchantUserId
            }
        },
        setSkus (state, skus) {

            if (skus == undefined) {

                skus = localStorage.getItem('skus')

                if (skus == undefined || skus == null) {

                    state.skus = []
                }
                else {

                    state.skus = skus.split(',')
                }
            }
            else {

                if (typeof skus == 'string') {

                    skus = skus.split(',')
                }

                localStorage.setItem('skus', skus.join(','))
                state.skus = skus
            }
        },
        setOptions (state, options) {
            
            if (options != undefined) {

                options.split(';').forEach(option => {

                    if (option == 'moretime') {

                        state.moreTime = 1
                    }  
                })
            }

            console.log('state.moreTime', state.moreTime)
        }
    }
}
